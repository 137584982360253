import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const CarDoor = () => {
    return (
        <AmenityWrapper>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.46094 9.89844C2.46094 10.3506 2.82904 10.7188 3.28125 10.7188C3.73346 10.7188 4.10156 10.3506 4.10156 9.89844H2.46094Z"
                    fill="white"
                />
                <path
                    d="M13.6395 5.64938L8.35064 0.3605C8.1183 0.128187 7.8091 0 7.48065 0H1.23047C0.551961 0 0 0.551961 0 1.23047V12.7695C0 13.448 0.551961 14 1.23047 14H7.82031C7.97573 14 8.11751 13.9123 8.18721 13.7733C9.12887 11.8891 11.05 10.7188 13.1569 10.7188H13.5898C13.8166 10.7188 14 10.5353 14 10.3086V6.51935C14 6.1909 13.8718 5.8817 13.6395 5.64938ZM4.92188 9.89844C4.92188 10.8033 4.18608 11.5391 3.28125 11.5391C2.37642 11.5391 1.64062 10.8033 1.64062 9.89844V9.48828C1.64062 9.26157 1.82407 9.07812 2.05078 9.07812H4.51172C4.73843 9.07812 4.92188 9.26157 4.92188 9.48828V9.89844ZM11.9492 7.41016H2.05078C1.82407 7.41016 1.64062 7.22671 1.64062 7V2.05078C1.64062 1.82407 1.82407 1.64062 2.05078 1.64062H7.1406C7.24954 1.64062 7.35369 1.68388 7.43058 1.7608L12.2392 6.56942C12.459 6.78921 12.4039 7.41016 11.9492 7.41016Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
