import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const AirportShuttle = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 3.77812V7.52665C0 7.78552 0.209875 7.9954 0.46875 7.9954C0.727625 7.9954 0.9375 7.78552 0.9375 7.52665L0.964562 2.24702C0.394469 2.52112 0 3.10437 0 3.77812Z"
                    fill="white"
                />
                <path
                    d="M15.0355 2.24702L15.0626 6.53249V7.52665C15.0626 7.78552 15.2725 7.9954 15.5313 7.9954C15.7902 7.9954 16.0001 7.78552 16.0001 7.52665V3.77812C16.0001 3.10437 15.6056 2.52112 15.0355 2.24702Z"
                    fill="white"
                />
                <path
                    d="M12.6916 0.636719H3.30823C2.53282 0.636719 1.90198 1.26756 1.90198 2.04297V12.0835C1.90198 12.7101 2.31398 13.2421 2.88126 13.4233V14.0941C2.88126 14.1975 2.89398 14.2978 2.91738 14.3941C3.05254 14.9496 3.55395 15.3633 4.15045 15.3633C4.73498 15.3633 5.22823 14.966 5.37495 14.4273C5.40388 14.321 5.41963 14.2094 5.41963 14.0941V13.4898H10.5802V14.0941C10.5802 14.2094 10.596 14.321 10.6249 14.4273C10.7717 14.966 11.2649 15.3633 11.8494 15.3633C12.4459 15.3633 12.9473 14.9496 13.0825 14.3941C13.1059 14.2978 13.1186 14.1974 13.1186 14.0941V13.4233H13.1186C13.6858 13.2421 14.0979 12.7101 14.0979 12.0835V2.04297C14.0979 1.26756 13.467 0.636719 12.6916 0.636719ZM4.88604 11.0568C4.88604 11.3156 4.67617 11.5255 4.41729 11.5255C4.15842 11.5255 3.94854 11.3156 3.94854 11.0568V9.9325C3.94854 9.67362 4.15842 9.46375 4.41729 9.46375C4.67617 9.46375 4.88604 9.67362 4.88604 9.9325V11.0568ZM12.0513 11.0568C12.0513 11.3157 11.8414 11.5255 11.5825 11.5255C11.3237 11.5255 11.1138 11.3157 11.1138 11.0568V9.93253C11.1138 9.67366 11.3237 9.46378 11.5825 9.46378C11.8414 9.46378 12.0513 9.67366 12.0513 9.93253V11.0568ZM13.1604 7.19191H2.83948V3.72191H13.1604V7.19191V7.19191Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
