import getSymbolFromCurrency from 'currency-symbol-map';
import { DateTime } from 'luxon';
import React from 'react';

import { FlightOffer, TextDirection } from '../chat/types';
import { ScaledImage } from './accommodation';
import { VanillaFlightTemplate } from './flights/vanilla';

export const FlightTemplate = (props: {
    offer: FlightOffer;
    scale: number;
    highlight?: boolean;
    direction: TextDirection;
}) => {
    const offer = props.offer;
    return (
        <ScaledImage scale={props.scale}>
            {VanillaFlightTemplate({
                highlight: props.highlight,
                tag: offer.tag_label || offer.Tags?.[0],
                price: offer.Price,
                currencySymbol: getSymbolFromCurrency(offer.Currency),
                direction: props.direction,
                outboundLeg: {
                    departureTime: DateTime.fromISO(offer.OutboundLeg.DepartureTime, {
                        zone: 'utc',
                    }).toFormat('HH:mm'),
                    departureAITA: offer.OutboundLeg.Segments[0].Origin,
                    arrivalTime: DateTime.fromISO(offer.OutboundLeg.ArrivalTime, {
                        zone: 'utc',
                    }).toFormat('HH:mm'),
                    arrivalIATA: offer.OutboundLeg.Destination.Code,
                    stops: offer.OutboundLeg.Segments ? offer.OutboundLeg.Segments.length - 1 : 0,
                    carrierCode: offer.OutboundLeg.Airlines[0],
                },
                inboundLeg:
                    offer.InboundLeg !== null
                        ? {
                              departureTime: DateTime.fromISO(offer.InboundLeg?.DepartureTime, {
                                  zone: 'utc',
                              }).toFormat('HH:mm'),
                              departureAITA: offer.InboundLeg?.Segments[0].Origin,
                              arrivalTime: DateTime.fromISO(offer.InboundLeg?.ArrivalTime, {
                                  zone: 'utc',
                              }).toFormat('HH:mm'),
                              arrivalIATA: offer.InboundLeg.Destination.Code,
                              stops: offer.InboundLeg?.Segments
                                  ? offer.InboundLeg.Segments.length - 1
                                  : 0,
                              carrierCode: offer.InboundLeg?.Airlines[0],
                          }
                        : undefined,
            })}
        </ScaledImage>
    );
};
