import React from 'react';

export const Send = ({ color = '#000000' }: { color?: string }) => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.561 2.03857L11.561 13.0386"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.561 2.03857L15.561 22.0386L11.561 13.0386L2.56104 9.03857L22.561 2.03857Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
