import { captureException } from '@sentry/minimal';
import { CardButton } from 'components/molecules/button';
import { copyToClipboard } from 'lib/utils';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    title: string;
    url: string;
    displayCallback?: () => void;
    successCallback?: () => void;
    guestMode: boolean;
}

export default function ShareButton({
    title,
    url,
    displayCallback,
    successCallback,
    guestMode,
}: IProps) {
    const router = useRouter();
    const [linkCopied, setLinkCopied] = useState(false);

    if (typeof navigator === 'undefined') {
        return null;
    }
    const { t } = useTranslation('chat');

    return (
        <CardButton
            buttonColor="primary"
            onPress={async () => {
                if (guestMode) {
                    await router.push('/login');
                    return;
                }
                displayCallback?.();
                try {
                    if (navigator.share) {
                        await navigator.share({
                            title: title,
                            url: url,
                        });
                    } else {
                        await copyToClipboard(url);
                        setLinkCopied(true);
                    }
                    successCallback?.();
                } catch (e) {
                    captureException(e);
                }
            }}
        >
            {linkCopied ? t('link-copied') : t('share')}
        </CardButton>
    );
}
