import React, { useEffect } from 'react';

export const useOutsideClick = (
    ref: React.RefObject<HTMLElement>,
    callbackFn: (target: HTMLElement) => void
) => {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                callbackFn(event.target as HTMLElement);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};
