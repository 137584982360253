import { useEffect, useState } from 'react';

export const useActiveElement = (): Element | null => {
    if (typeof document === 'undefined') {
        return null;
    }
    const [active, setActive] = useState(document.activeElement);

    const handleFocusIn = (_: Event) => {
        setActive(document.activeElement);
    };

    useEffect(() => {
        document.addEventListener('focusin', handleFocusIn);
        return () => {
            document.removeEventListener('focusin', handleFocusIn);
        };
    }, []);

    return active;
};
