import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';

import { ActivityOffer, TextDirection } from '../chat/types';
import { ScaledImage } from './accommodation';
import { VanillaActivitiesTemplate } from './activities/vanilla';

export const ActivitiesTemplate = (props: {
    offer: ActivityOffer;
    scale: number;
    direction: TextDirection;
}) => {
    const offer = props.offer;

    return (
        <ScaledImage scale={props.scale}>
            {VanillaActivitiesTemplate({
                price: offer.price,
                priceDescription: offer.price_description,
                imageUrl: (offer.images || [])[0],
                currencySymbol: getSymbolFromCurrency(offer.currency),
                rating: offer.rating,
                ratingAmount: offer.num_ratings,
                priceLabel: offer.price_label,
                ratingLabel: offer.rating_label,
                direction: props.direction,
            })}
        </ScaledImage>
    );
};
