import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const FitnessRoom = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.32736 6.05856L0.00180054 7.38408L1.32736 8.70955L0.00180054 10.0351L5.96662 15.9999L7.29218 14.6744L8.61764 15.9999L9.94323 14.6745L1.32736 6.05856Z"
                    fill="white"
                />
                <path
                    d="M8.70773 5.30208L5.30386 8.70957L7.29217 10.6979L10.696 7.29039L8.70773 5.30208Z"
                    fill="white"
                />
                <path
                    d="M15.9982 5.96482L10.0333 0L8.70788 1.32549L7.38229 0L6.05676 1.32553L14.6727 9.9414L15.9982 8.61584L14.6727 7.29032L15.9982 5.96482Z"
                    fill="white"
                />
                <path
                    d="M14.0098 -0.0001064L12.6844 1.32537L14.6726 3.31359L15.9981 1.98812L14.0098 -0.0001064Z"
                    fill="white"
                />
                <path
                    d="M1.32766 12.6863L0.00216675 14.0118L1.99039 16L3.31589 14.6745L1.32766 12.6863Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
