import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const Bar = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.49024 6.60982C6.06702 6.22526 6.80733 6.20326 7.40264 6.54347L8.65394 4.03112H3.00026C2.62299 4.03112 2.40033 4.45555 2.61436 4.76599L4.39125 7.3425C6.24327 6.10779 4.68772 7.14472 5.49024 6.60982Z"
                    fill="white"
                />
                <path
                    d="M11.4998 15.0626H8.46856V11.8961L11.0765 8.11463C9.68189 7.18492 10.0941 7.45973 9.98983 7.3902C9.69211 7.19164 9.30749 7.19164 9.0099 7.39014C8.68468 7.60695 8.29178 7.71942 7.87778 7.69182L7.37113 8.70913C7.28916 8.87375 7.12338 8.96897 6.9512 8.96897C6.60667 8.96897 6.37592 8.60453 6.53198 8.29116L6.98285 7.38586C6.69039 7.1948 6.30848 7.19114 6.00986 7.39017C5.88939 7.47048 6.37058 7.1497 4.92318 8.11463L7.5311 11.8961V15.0626H4.49987C4.241 15.0626 4.03113 15.2725 4.03113 15.5314C4.03113 15.7902 4.241 16.0001 4.49987 16.0001H11.4998C11.7587 16.0001 11.9686 15.7902 11.9686 15.5314C11.9686 15.2725 11.7587 15.0626 11.4998 15.0626Z"
                    fill="white"
                />
                <path
                    d="M13.0005 4.03135H10.7499L12.4201 0.677874C12.5355 0.446125 12.4412 0.164721 12.2095 0.0493161C11.9777 -0.0661517 11.6963 0.0281913 11.581 0.259908C9.85436 3.72663 10.1149 3.20167 8.38818 6.66851C8.42334 6.6513 8.45762 6.63205 8.49056 6.61008C9.10412 6.20089 9.89708 6.20092 10.5106 6.61011C11.3348 7.15942 9.76173 6.11083 11.6095 7.34273L13.3864 4.76622C13.6006 4.45559 13.3775 4.03135 13.0005 4.03135V4.03135Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
