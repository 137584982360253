import { AccommodationDeal } from 'components/chat/types';
import styled from 'styled-components';

const DealBlackFridayContainer = styled.div({
    position: 'absolute',
    height: '84px',
    width: '220px',
    top: '33px',
    right: '0',
    display: 'flex',
    flexDirection: 'column',
});

const DealNameBlackFriday = styled.div((props) => ({
    background: '#000000',
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '27px',
    lineHeight: '30px',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    letterSpacing: '0.5px',
    borderTopLeftRadius: '12px',
    padding: '4px',
    paddingTop: '8px',
}));

const DealDiscountBlackFriday = styled.div((props) => ({
    background: '#FF0000',
    fontFamily: props.theme.fonts.bold,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: '34px',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    letterSpacing: '3px',
    borderBottomLeftRadius: '12px',
    padding: '4px',
}));

export const BlackFriday = (props: AccommodationDeal) => {
    return (
        <DealBlackFridayContainer>
            <DealNameBlackFriday>{props.name}</DealNameBlackFriday>
            <DealDiscountBlackFriday>{`-${props.discount_percentage}%`}</DealDiscountBlackFriday>
        </DealBlackFridayContainer>
    );
};
