import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const Person = () => {
    return (
        <AmenityWrapper>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.6667 13.5C12.6667 13.7761 12.4428 14 12.1667 14H1.5C1.22386 14 1 13.7761 1 13.5V13.25C1 12.2831 1.38411 11.3558 2.06784 10.672C2.75157 9.9883 3.6789 9.60419 4.64583 9.60419H9.02083C9.98777 9.60419 10.9151 9.9883 11.5988 10.672C12.2826 11.3558 12.6667 12.2831 12.6667 13.25V13.5Z"
                    fill="white"
                />
                <circle cx="6.83337" cy="4.5" r="3.5" fill="white" />
            </svg>
        </AmenityWrapper>
    );
};
