import { Image } from 'components/atoms/image';
import { Link } from 'components/atoms/link';
import { Text } from 'components/atoms/text';
import { AspectRatio, TextDirection } from 'components/chat/types';
import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import styledWeb from 'styled-components';
import styled from 'styled-components/native';

const InnerCard = styledWeb.div((props) => ({
    border: `1px solid ${props.theme.colors.grey}`,
    borderRadius: props.theme.borderRadius.cards,
    width: '16.5rem',
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
    marginTop: '5px',
    marginBottom: '10px',
    overflow: 'hidden',
}));

const Body = styled(View)<{ paddingBottom?: string }>((props) => ({
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    paddingBottom: props.paddingBottom ? props.paddingBottom : '0.75rem',
    cursor: 'pointer',
}));

const Subtitle = styledWeb.div<{
    direction?: TextDirection;
}>((props) => ({
    color: props.theme.colors.darkgrey,
    fontFamily: `${props.theme.fonts.default}`,
    lineHeight: '16px',
    fontSize: 12,
    fontWeight: 400,
    overflow: 'hidden',
    display: '-webkit-box',
    ' -webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    height: 60,
    direction: props.direction || 'ltr',
    whiteSpace: 'pre-wrap',
}));

const Title = styledWeb.div<{
    direction?: TextDirection;
}>((props) => ({
    lineHeight: '17px',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: `${props.theme.fonts.default}`,
    color: props.theme.colors.text,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    direction: props.direction || 'ltr',
}));

const baseImageStyle = (borderColor: string) => ({
    width: 262,
    height: 134,
    border: `1px solid ${borderColor}`,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
});

const CardImage = styled(Image)((props) => ({
    ...baseImageStyle(props.theme.colors.grey),
    height:
        props.aspectRatio === AspectRatio.FourThree
            ? 197
            : props.aspectRatio === AspectRatio.Square
            ? 262
            : 134,
}));

const ImageWrapper = styled(View)((props) => ({
    ...baseImageStyle(props.theme.colors.grey),
    overflow: 'hidden',
}));

const SecondaryButton = styled(View)({
    marginTop: '0.5rem',
});

interface CardProps {
    imageUrl: string;
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    aspectRatio?: number;
    url?: string;
    externalURL?: boolean;
    onPress?: (event?: unknown | MouseEvent) => void;
    primaryButton?: JSX.Element;
    secondaryButton?: JSX.Element;
    image?: JSX.Element;
    shareUrl?: string;
    paddingBottom?: string;
    direction?: TextDirection;
}

export const Card = ({
    imageUrl,
    title,
    subtitle,
    aspectRatio,
    url,
    onPress,
    primaryButton,
    secondaryButton,
    image,
    paddingBottom,
    externalURL,
    direction,
}: CardProps) => {
    return (
        <InnerCard>
            {url ? (
                image ? (
                    <Link
                        href={!externalURL ? `${url}?btn=image` : url}
                        target="_blank"
                        onPress={onPress}
                    >
                        <ImageWrapper>{image}</ImageWrapper>
                    </Link>
                ) : (
                    <Link
                        href={!externalURL ? `${url}?btn=image` : url}
                        target="_blank"
                        onPress={onPress}
                    >
                        <View>
                            <CardImage imageUrl={imageUrl} aspectRatio={aspectRatio} />
                        </View>
                    </Link>
                )
            ) : (
                <TouchableWithoutFeedback onPress={onPress}>
                    <View>
                        <CardImage imageUrl={imageUrl} aspectRatio={aspectRatio} />
                    </View>
                </TouchableWithoutFeedback>
            )}
            <TouchableWithoutFeedback
                onPress={(event) => {
                    onPress?.(event);
                    if (url) {
                        window.open(!externalURL ? `${url}?btn=card` : url, '_blank');
                    }
                }}
            >
                <Body paddingBottom={paddingBottom}>
                    {typeof title === 'string' ? (
                        <Title direction={direction}>{title}</Title>
                    ) : (
                        title
                    )}
                    {typeof subtitle === 'string' ? (
                        <Subtitle direction={direction}>{subtitle}</Subtitle>
                    ) : (
                        subtitle
                    )}
                    {primaryButton}
                    {secondaryButton ? (
                        <SecondaryButton>{secondaryButton}</SecondaryButton>
                    ) : undefined}
                </Body>
            </TouchableWithoutFeedback>
        </InnerCard>
    );
};

export default Card;
