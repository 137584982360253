import getSymbolFromCurrency from 'currency-symbol-map';
import { GroupUser, User } from 'lib/types';

import { ConversationItem } from './types';

export const getMessageUserId = (message: ConversationItem) => message?.user?.user_id.split(':')[1];

export const sentByCurrentUser = (message: ConversationItem, user?: User) => {
    // previously we did not have a user.id, only user
    // thus assume that it is current user.
    if (message?.user && (!message.user.user_id || message.user.user_id === '')) {
        return true;
    }

    return getMessageUserId(message) === user?.id;
};

export const getUserInitials = (user?: Partial<GroupUser>) => {
    if (user?.first_name) {
        return user.first_name.normalize('NFKD').replace(/[^\w]/g, '').substr(0, 2);
    } else if (user?.email) {
        return user.email.substr(0, 2);
    }
};
