import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const Restaurant = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.5005 0H12.0004C11.0354 0 10.2504 0.785059 10.2504 1.75005V10.4998H12.0004V16H14.0005V0H13.5005Z"
                    fill="white"
                />
                <path
                    d="M7.00018 0H6.00015V2.75008H4.50011V0H3.50008V2.75008H2.00003V0H1V4.43141C1 5.43425 1.79946 6.25019 2.78216 6.25019H3.00006V15.9999H3.50008V16H4.50011V15.9999H5.00012V6.25019H5.21815C6.20084 6.25019 7.00031 5.43425 7.00031 4.43141L7.00018 0Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
