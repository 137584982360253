import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const InternetAccess = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.99994 11.3029C7.21941 11.3029 6.58441 11.9379 6.58441 12.7185C6.58441 13.499 7.21941 14.134 7.99994 14.134C8.78047 14.134 9.41547 13.499 9.41547 12.7185C9.41547 11.9379 8.78047 11.3029 7.99994 11.3029Z"
                    fill="white"
                />
                <path
                    d="M7.9999 8.15734C6.57281 8.15734 5.2969 8.81631 4.4599 9.84577L5.99806 11.3839C5.99806 11.3839 6.71546 10.3604 7.99993 10.3604C9.25818 10.3604 10.0018 11.3839 10.0018 11.3839L11.54 9.84577C10.7029 8.81631 9.42702 8.15734 7.9999 8.15734Z"
                    fill="white"
                />
                <path
                    d="M8 1.86613C2.93947 1.86613 0.106156 5.27019 0 5.38585L1.56178 6.94763C3.14669 5.18128 5.44566 4.0681 8 4.0681C10.5543 4.0681 12.8533 5.18132 14.4382 6.94763L16 5.38582C15.8938 5.27019 13.054 1.86613 8 1.86613Z"
                    fill="white"
                />
                <path
                    d="M8.00001 5.01166C5.70583 5.01166 3.64295 6.01969 2.22992 7.61566L3.79014 9.17587C4.80073 7.97681 6.31279 7.2136 8.00001 7.2136C9.6872 7.2136 11.1993 7.97681 12.2099 9.17587L13.7701 7.61566C12.3571 6.01966 10.2942 5.01166 8.00001 5.01166Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
