import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const FacilitiesForDisabled = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.52517 14.1276C4.51104 14.1276 2.87253 12.4891 2.87253 10.4749C2.87253 9.23755 3.50972 8.07774 4.54686 7.40675L4.17597 5.47559C2.2367 6.38685 1 8.31683 1 10.4748C1 13.5211 3.47863 15.9999 6.52517 15.9999C8.01701 15.9999 9.45445 15.3876 10.4936 14.3144L9.69456 12.2888C9.05027 13.4086 7.84162 14.1276 6.52517 14.1276Z"
                    fill="white"
                />
                <path
                    d="M14.1115 14.5975L11.582 8.18325C11.4695 7.72449 11.0609 7.40345 10.5885 7.40277L7.86671 7.39923L7.67644 6.40872H10.6096C11.1755 6.40872 11.6356 5.94861 11.6356 5.38324C11.6356 4.81752 11.1752 4.35742 10.6096 4.35742H7.28224L7.11293 3.47573L7.20468 3.38093C7.56763 3.00616 7.76752 2.51361 7.76752 1.99419C7.76752 0.894531 6.87299 0 5.77316 0C4.6735 0 3.77914 0.894531 3.77914 1.99419C3.77914 2.78751 4.24972 3.5053 4.97815 3.82262L5.10066 3.87585L6.01175 8.61784C6.01529 8.6344 6.01867 8.64724 6.02222 8.66042L6.05196 8.76282C6.0572 8.77752 6.06261 8.79323 6.06869 8.80827L6.08356 8.84224C6.09285 8.86268 6.10113 8.88059 6.11009 8.89783L6.12681 8.92807C6.13695 8.94581 6.14794 8.9644 6.15943 8.98197L6.17666 9.00783C6.18916 9.02574 6.20268 9.04382 6.21671 9.06122L6.23225 9.08082C6.24797 9.09941 6.26436 9.11732 6.28108 9.13472L6.2941 9.14824C6.31657 9.17105 6.33685 9.18913 6.35763 9.20671L6.373 9.22039C6.39092 9.23492 6.41592 9.25351 6.44161 9.27125L6.46712 9.2873C6.48317 9.29761 6.50108 9.3091 6.52018 9.31941L6.54231 9.33073C6.57121 9.34526 6.5881 9.35405 6.60551 9.36182L6.63609 9.37432C6.65958 9.38362 6.67698 9.39021 6.69489 9.39612L6.72987 9.40694C6.75065 9.41319 6.77144 9.41809 6.79239 9.42316L6.82365 9.43008C6.84781 9.43465 6.87333 9.43853 6.89901 9.44124L6.91895 9.44428C6.94734 9.44715 6.97826 9.44918 7.00952 9.44952L7.02405 9.44918L9.87644 9.45273L12.2033 15.3498C12.3591 15.7442 12.7339 15.9993 13.1579 15.9993C13.2861 15.9993 13.4128 15.9748 13.5343 15.927C14.06 15.7204 14.319 15.1236 14.1115 14.5975Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
