import {
    AccommodationOffer,
    AccommodationSearchQuery,
    ISendMessageOptions,
    LocationType,
    MessageOfferType,
    TextDirection,
} from 'components/chat/types';
import ShareButton from 'components/chat/ui/ShareButton';
import { CardLink } from 'components/molecules/link';
import { SubtitleText } from 'components/molecules/text';
import { Card } from 'components/organisms/card';
import { AccommodationTemplate } from 'components/templates';
import { ClickedOnAccommodationOfferEvent, Event, sendEvent } from 'lib/events';
import { GTM_EVENTS } from 'lib/gtmEvents';
import getConfig from 'next/config';
import React from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Likes } from './Likes';

const { publicRuntimeConfig } = getConfig();

const roundDistance = (distance: number) => {
    if (distance < 1000) {
        let rounded = Math.floor(distance / 50) * 50;
        if (distance % 50 >= 25) {
            rounded += 50;
        }
        if (rounded === 0) {
            rounded = 50;
        }
        if (rounded === 1000) {
            return '1 km';
        }
        return `${rounded} m`;
    } else if (distance < 5000) {
        return `${parseFloat((distance / 1000).toFixed(1)).toString()} km`;
    } else {
        return `${(distance / 1000).toFixed(0)} km`;
    }
};

const distanceStatement = (offer: AccommodationOffer, searchQuery?: AccommodationSearchQuery) => {
    if (!searchQuery) {
        return '';
    }
    let poi = 'centre';
    if (searchQuery.Location.Type === LocationType.Airport) {
        poi = 'airport';
    }

    const rounded = roundDistance(offer.distance);

    return `📍 ${rounded} from ${poi}\n`;
};

const highestPriorityOption = (offer: AccommodationOffer) =>
    offer.options.sort((a, b) => a.rank - b.rank)[0];

const Subtitle_DEPRECATED = (
    offer: AccommodationOffer,
    searchQuery?: AccommodationSearchQuery,
    direction?: TextDirection
) => (
    <View style={{ paddingBottom: '0.25rem' }}>
        {offer.options?.length > 0 ? (
            <SubtitleText direction={direction}>{`${
                highestPriorityOption(offer).name
            }\n`}</SubtitleText>
        ) : null}
        <SubtitleText direction={direction}>
            {offer.distance < 30000 ? distanceStatement(offer, searchQuery) : <>&nbsp;</>}
        </SubtitleText>
    </View>
);

const Subtitle = (subtitle: string, direction?: TextDirection) => (
    <View style={{ paddingBottom: '0.25rem' }}>
        <SubtitleText direction={direction}>{subtitle}</SubtitleText>
    </View>
);

const handleSharePress = () =>
    TagManager.dataLayer({
        dataLayer: {
            event: GTM_EVENTS.ACCOMMODATION_SHARE,
        },
    });

const handleShareCallback = () =>
    TagManager.dataLayer({
        dataLayer: {
            event: GTM_EVENTS.CONTINUED_ACCOMMODATION_SHARE,
        },
    });

const handleClickedOnAccommodation = (
    offer: AccommodationOffer,
    query?: AccommodationSearchQuery
) => {
    const clickedOnAccommodationEvent: ClickedOnAccommodationOfferEvent = {
        type: Event.CLICKED_ON_ACCOMMODATION_OFFER,
        accommodation_type: offer.type,
        provider: offer.provider,
        name: offer.name,
        stars: offer.stars,
        currency: offer.currency,
        price_per_night: offer.price_per_night,
        total_price: offer.total_price,
        rooms: offer.rooms,
        check_in_date: query?.CheckInDate,
        check_out_date: query?.CheckOutDate,
        location: query?.Location?.NameEn,
        external_id: offer.external_id,
    };
    sendEvent(clickedOnAccommodationEvent);
    TagManager.dataLayer({
        dataLayer: {
            event: GTM_EVENTS.CLICKED_ON_ACCOMMODATION,
        },
    });
};

interface IProps {
    searchQuery?: AccommodationSearchQuery;
    offer: AccommodationOffer;
    buttonTitle?: string;
    detailsURL: string;
    imageUrl: string;
    searchId: string;
    index: number;
    guestMode: boolean;
    sendMessage: (text: string, options: ISendMessageOptions) => void;
    isWidget: boolean;
    direction: TextDirection;
}
export const AccommodationCard = React.memo(
    ({
        searchQuery,
        offer,
        detailsURL,
        imageUrl,
        searchId,
        index,
        guestMode,
        sendMessage,
        isWidget,
        direction,
    }: IProps) => {
        const { t } = useTranslation('cards');
        return (
            <>
                <Card
                    onPress={() => handleClickedOnAccommodation(offer, searchQuery)}
                    imageUrl={imageUrl}
                    title={offer.title}
                    subtitle={
                        offer.subtitle
                            ? Subtitle(offer.subtitle, direction)
                            : Subtitle_DEPRECATED(offer, searchQuery, direction)
                    }
                    url={detailsURL}
                    primaryButton={
                        !isWidget ? (
                            <ShareButton
                                title={offer.title}
                                url={getShareUrl(offer, searchId, index)}
                                displayCallback={handleSharePress}
                                successCallback={handleShareCallback}
                                guestMode={guestMode}
                            />
                        ) : undefined
                    }
                    secondaryButton={
                        <CardLink
                            href={`${detailsURL}?btn=details`}
                            onPress={() => handleClickedOnAccommodation(offer, searchQuery)}
                        >
                            {t('accommodation.view-details')}
                        </CardLink>
                    }
                    image={
                        <AccommodationTemplate offer={offer} scale={0.328} direction={direction} />
                    }
                    paddingBottom={offer.id ? '1.25rem' : '0.75rem'}
                    direction={direction}
                />
                {offer.id ? (
                    <Likes
                        offerId={offer.id}
                        sendMessage={sendMessage}
                        offerType={MessageOfferType.Accommodation}
                    />
                ) : null}
            </>
        );
    }
);

const getShareUrl = (offer: AccommodationOffer, searchID: string, index: number) => {
    if (offer.id) {
        return `${publicRuntimeConfig?.rootUrl}/share/hotels/${offer.id}`;
    } else return `${publicRuntimeConfig?.rootUrl}/share/hotels/${searchID}-${index}`;
};
