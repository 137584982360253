import { openLink } from 'lib/utils';
import React, { useState } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import styled from 'styled-components/native';

interface LinkProps {
    hover?: boolean;
}

const InnerLink = styled.Text<LinkProps>((props) => ({
    cursor: 'pointer',
    fontFamily: `'${props.theme.fonts.default}'`,
    accessibilityRole: 'link',
    textDecoration: props.hover ? 'underline' : 'none',
}));

export const Link = (props: any) => {
    const [hover, setHover] = useState(false);
    const { children, ...rest } = props;
    return (
        <TouchableWithoutFeedback
            accessibilityRole="link"
            onPress={(event) => {
                if (props.onPress) {
                    props.onPress(event);
                }
                openLink(props.href, props.target);
                event.preventDefault();
                return false;
            }}
        >
            <InnerLink
                {...rest}
                hover={hover}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {children}
            </InnerLink>
        </TouchableWithoutFeedback>
    );
};
