import { Bubble } from 'components/atoms/Bubble';
import { Link } from 'components/atoms/link';
import { CardButton } from 'components/molecules/button';
import { CardLink } from 'components/molecules/link';
import { Card } from 'components/organisms/card';
import { ClickedCardEvent, ClickedOnMessageLink, Event, sendEvent } from 'lib/events';
import { useStore } from 'lib/store';
import { openLink } from 'lib/utils';
import Linkify from 'linkify-react';
import React, { memo } from 'react';
import styled, { useTheme } from 'styled-components';

import { useChatEventSender } from '../hooks/UseChatEventSender';
import { useGroupState, useRequireRegistrationState, useUserState } from '../hooks/UseGlobalState';
import {
    Action,
    ActionType,
    ActivityOffer,
    CarHireOffer,
    IChatBubble,
    ISendMessageOptions,
    Image as ImageType,
    Place,
    TextDirection,
} from '../types';
import { getMessageUserId, sentByCurrentUser } from '../utils';
import { AccommodationCarousel } from './AccommodationCarousel';
import { ActivityCard } from './ActivityCard';
import Carousel from './Carousel';
import { CarRentalCard } from './CarRentalCard';
import { FlightCarousel } from './FlightCarousel';
import { PlaceCard } from './PlaceCard';
import ShareButton from './ShareButton';

const BubbleWrapper = styled.div({
    overflow: 'auto',
});

const BotBubble = styled(Bubble)((props) => ({
    color: props.theme.colors.text,
    backgroundColor: props.theme.colors.primaryLight,
    borderRadius: props.theme.borderRadius.botBubble,
}));

const UserBubble = styled(Bubble)((props) => ({
    backgroundColor: '#F1F0F0',
    borderRadius: props.theme.borderRadius.userBubble,
}));

const OtherUserBubble = styled(Bubble)<{ groupUserIndex: number }>((props) => ({
    backgroundColor: props.theme.getProfileColor(props.groupUserIndex).bubbleColor,
    borderRadius: props.theme.borderRadius.botBubble,
}));

const StyledMessage = styled.div<{ paddingBottom: number; direction: 'rtl' | 'ltr' }>((props) => ({
    fontSize: '16px',
    lineHeight: '20px',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
    margin: 0,
    fontFamily: props.theme.fonts.default,
    color: props.theme.colors.text,
    paddingBottom: props.paddingBottom,
    direction: props.direction,
}));

const BotImage = styled.img((props) => ({
    width: 'auto',
    height: '16.5rem',
    border: `1px solid ${props.theme.colors.grey}`,
    borderRadius: 10,
}));

const TagRegex = new RegExp(/(<@[a-zA-Z0-9]+>)/gi);

const TagMessage = styled.span<{ tagColor: string }>((props) => ({
    color: props.tagColor,
}));

const Message = memo(
    ({
        message,
        tagColor,
        hasButton,
        direction,
    }: {
        message: string;
        tagColor: string;
        hasButton?: boolean;
        direction: TextDirection;
    }) => {
        const messageParts = message.split(TagRegex).filter((part) => !!part);
        return (
            <StyledMessage
                className="styled-message-bubble"
                paddingBottom={hasButton ? 8 : 0}
                direction={direction}
            >
                <Linkify options={{ target: '_blank' }}>
                    {messageParts.map((part, index) => {
                        if (TagRegex.test(part)) {
                            return (
                                <TagMessage tagColor={tagColor} key={index}>
                                    {part.split('<').pop()!.split('>')[0]}
                                </TagMessage>
                            );
                        }
                        return <span key={index}>{part}</span>;
                    })}
                </Linkify>
            </StyledMessage>
        );
    }
);

export const MessageButton = styled(Link)`
    text-decoration: none;
    display: block;
    height: 40px;
    padding: inherit;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    background: #feb001;
    border-radius: 4px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
`;

const action2button = (props: {
    action: Action;
    sendMessage: (text: string, options: ISendMessageOptions) => void;
    guestMode: boolean;
    isWidget: boolean;
    primary?: boolean;
    onPress?: () => void;
}) => {
    const { action, sendMessage, guestMode, isWidget, primary, onPress } = props;
    switch (action?.Type) {
        case ActionType.URL:
            return (
                <CardLink
                    buttonColor={primary ? 'primary' : ''}
                    href={action.URL}
                    onPress={onPress}
                >
                    {action.Title}
                </CardLink>
            );
        case ActionType.Postback:
            return (
                <CardButton
                    buttonColor={primary ? 'primary' : ''}
                    onPress={() => {
                        if (onPress) {
                            onPress();
                        }
                        sendMessage(action.Title, { assistant_disabled: false, click: true });
                    }}
                >
                    {action.Title}
                </CardButton>
            );
        case ActionType.Share:
            return !isWidget ? (
                <ShareButton title={action.Title} url={action.URL} guestMode={guestMode} />
            ) : undefined;
    }
};

// eslint-disable-next-line complexity
const Renderer = memo(({ message, sendMessage, direction, index }: IChatBubble) => {
    const theme = useTheme();
    const user = useUserState((state) => state.user);
    const groupUsers = useGroupState((state) => state.users);
    const guestMode = !!user?.guest;
    const isWidget = useStore((store) => store.isWidget);
    const requireRegistration = useRequireRegistrationState((state) => state.requireRegistration);
    const sendChatEvent = useChatEventSender();
    const sendCardClickEvent = (
        cardTitle: string,
        action?: Action,
        trackingID?: string,
        trackingLabel?: string // eslint-disable-next-line max-params
    ) => {
        if (!action) {
            return;
        }
        const properties: Record<string, string> = { title: cardTitle, button_title: action.Title };
        if (trackingID) {
            properties.tracking_id = trackingID;
        }
        if (trackingLabel) {
            properties.tracking_label = trackingLabel;
        }
        if (action.URL) {
            properties.url = action.URL;
        }
        sendChatEvent('Clicked on Carousel Card', properties);
        const chatEvent: ClickedCardEvent = {
            type: Event.CLICKED_CARD,
            card_title: cardTitle,
            button_title: action.Title,
            tracking_id: trackingID,
            tracking_label: trackingLabel,
        };
        sendEvent(chatEvent);
    };
    const onCardPress = (event: MouseEvent, image: ImageType, actionTitle: string) => {
        sendCardClickEvent(
            image.title,
            image.actions?.[0],
            image.tracking_id,
            image.tracking_label
        );
        if (image.default_action.Type === ActionType.URL) {
            openLink(image.default_action.URL, '_blank');
        } else if (image.default_action.Type === ActionType.Share) {
            (event as MouseEvent).preventDefault();
        } else {
            sendMessage(actionTitle, {
                assistant_disabled: false,
                click: true,
            });
        }
    };
    if (message.user) {
        let text = message.user.text || '';
        if (message.user.deeplink && message.user.deeplink !== '') {
            text = 'Deeplink: ' + text;
        } else if (message.user.audio_clip) {
            text = 'Transcription: ' + text;
        }
        if (!!user && !sentByCurrentUser(message, user)) {
            return (
                <BubbleWrapper>
                    <OtherUserBubble
                        isFirst={index === 0}
                        groupUserIndex={groupUsers.findIndex(
                            (user) => user.id === getMessageUserId(message)
                        )}
                    >
                        <Message
                            message={text}
                            tagColor={theme.colors.primaryActive}
                            direction={direction}
                        />
                    </OtherUserBubble>
                </BubbleWrapper>
            );
        }
        return (
            <BubbleWrapper>
                <UserBubble isFirst={index === 0}>
                    <Message message={text} tagColor={theme.colors.primary} direction={direction} />
                </UserBubble>
            </BubbleWrapper>
        );
    } else if (message.bot) {
        if (message.bot.flight_search_result) {
            return (
                <FlightCarousel
                    flightResult={message.bot.flight_search_result}
                    guestMode={guestMode}
                    sendMessage={sendMessage}
                    isWidget={isWidget}
                    direction={direction}
                />
            );
        } else if (message.bot.accommodation_search_result) {
            return (
                <AccommodationCarousel
                    accommodationResult={message.bot.accommodation_search_result}
                    guestMode={guestMode}
                    sendMessage={sendMessage}
                    isWidget={isWidget}
                    direction={direction}
                />
            );
        } else if (message.bot.car_hire_search_result) {
            const query = message.bot.car_hire_search_result.Query;
            return (
                <Carousel>
                    {message.bot.car_hire_search_result.SelectedOffers.map(
                        (offer: CarHireOffer, i: number) => {
                            let bookingUrl = offer.booking_url;
                            if (requireRegistration && guestMode) {
                                bookingUrl = `/login?redirect_url=${bookingUrl}`;
                            }
                            return (
                                <CarRentalCard
                                    key={i}
                                    offer={offer}
                                    bookingUrl={bookingUrl}
                                    sendMessage={sendMessage}
                                    query={query}
                                    direction={direction}
                                />
                            );
                        }
                    )}
                </Carousel>
            );
        } else if (message.bot.activity_search_result) {
            return (
                <Carousel>
                    {message.bot.activity_search_result.SelectedOffers.map(
                        (activityOffer: ActivityOffer, i: number) => (
                            <ActivityCard
                                key={i}
                                offer={activityOffer}
                                query={message?.bot?.activity_search_result?.Query}
                                sendMessage={sendMessage}
                                direction={direction}
                            />
                        )
                    )}
                </Carousel>
            );
        } else if (message.bot.places) {
            return (
                <Carousel>
                    {message.bot.places.map((place: Place, i: number) => (
                        <PlaceCard key={i} place={place} direction={direction} />
                    ))}
                </Carousel>
            );
        } else if (message.bot.gif) {
            return <BotImage src={message.bot.gif} />;
        } else if (message.bot.image) {
            const image = message.bot.image;
            const actionTitle = image.actions?.[0]?.Title || image.title;
            return (
                <Card
                    imageUrl={image.url}
                    title={image.title}
                    subtitle={image.subtitle}
                    aspectRatio={image.aspect_ratio}
                    direction={direction}
                    onPress={(event) => onCardPress(event as MouseEvent, image, actionTitle)}
                    primaryButton={action2button({
                        action: image.actions?.[0],
                        sendMessage,
                        guestMode,
                        isWidget,
                        onPress: () =>
                            sendCardClickEvent(
                                image.title,
                                image.actions?.[0],
                                image.tracking_id,
                                image.tracking_label
                            ),
                    })}
                    secondaryButton={
                        image.actions?.[1]
                            ? action2button({
                                  action: image.actions?.[1],
                                  sendMessage,
                                  guestMode,
                                  isWidget,
                                  onPress: () =>
                                      sendCardClickEvent(
                                          image.title,
                                          image.actions?.[1],
                                          image.tracking_id,
                                          image.tracking_label
                                      ),
                              })
                            : undefined
                    }
                />
            );
        } else if (message?.bot.carousel) {
            return (
                <Carousel>
                    {message.bot.carousel.images.map((image: ImageType, i: number) => {
                        const actionTitle = image.actions?.[0]?.Title || image.title;
                        return (
                            <Card
                                key={i}
                                imageUrl={image.url}
                                title={image.title}
                                subtitle={image.subtitle}
                                aspectRatio={image.aspect_ratio}
                                direction={direction}
                                onPress={(event) =>
                                    onCardPress(event as MouseEvent, image, actionTitle)
                                }
                                primaryButton={action2button({
                                    action: image.actions?.[0],
                                    sendMessage,
                                    guestMode,
                                    isWidget,
                                    primary: !!image.actions?.[1],
                                    onPress: () =>
                                        sendCardClickEvent(
                                            image.title,
                                            image.actions?.[0],
                                            image.tracking_id,
                                            image.tracking_label
                                        ),
                                })}
                                secondaryButton={
                                    image.actions?.[1]
                                        ? action2button({
                                              action: image.actions?.[1],
                                              sendMessage,
                                              guestMode,
                                              isWidget,
                                              onPress: () =>
                                                  sendCardClickEvent(
                                                      image.title,
                                                      image.actions?.[1],
                                                      image.tracking_id,
                                                      image.tracking_label
                                                  ),
                                          })
                                        : undefined
                                }
                            />
                        );
                    })}
                </Carousel>
            );
        } else {
            const hasButton = !!message.bot.actions && message.bot.actions.length > 0;
            return (
                <BubbleWrapper>
                    <BotBubble isFirst={index === 0}>
                        <Message
                            message={message.bot.text!}
                            tagColor={theme.colors.primary}
                            hasButton={hasButton}
                            direction={direction}
                        />
                        {hasButton
                            ? action2button({
                                  action: message.bot.actions![0],
                                  sendMessage,
                                  guestMode,
                                  isWidget,
                                  onPress: () => {
                                      const chatEvent: ClickedOnMessageLink = {
                                          type: Event.CLICKED_ON_MESSAGE_LINK,
                                          url: message.bot?.actions![0].URL,
                                      };
                                      sendEvent(chatEvent);
                                  },
                              })
                            : null}
                    </BotBubble>
                </BubbleWrapper>
            );
        }
    }
    return <div />;
});

export default Renderer;
