import { Spinner } from 'components/icons/Spinner';
import { Scroller } from 'components/organisms/Scroller';
import React, { memo } from 'react';
import styled from 'styled-components/native';

import { useCarouselHandler } from '../hooks/UseCarouselHandler';
import { useRequireRegistrationState } from '../hooks/UseGlobalState';
import { FlightOffer, FlightSearchResult, ISendMessageOptions, TextDirection } from '../types';
import { CardWrapperDiv } from './Carousel';
import { FlightCard } from './FlightCard';

const SpinnerWrapper = styled.View({
    paddingRight: '16px',
    display: 'flex',
    justifyContent: 'center',
});

export const FlightCarousel = memo(
    (props: {
        flightResult: FlightSearchResult;
        guestMode: boolean;
        sendMessage: (text: string, options: ISendMessageOptions) => void;
        isWidget: boolean;
        direction: TextDirection;
    }) => {
        const { offers, inViewRef, isLoading } = useCarouselHandler(
            props.flightResult.SelectedOffers,
            props.flightResult.ID
        );
        const flightOffers = offers as Array<FlightOffer>;
        const requireRegistration = useRequireRegistrationState(
            (state) => state.requireRegistration
        );
        return (
            <Scroller leftButtonOffset={0} step={272} verticalCenter="24%">
                {flightOffers.map((offer, i) => {
                    const isLastCard = flightOffers.length - 1 === i;
                    let bookingUrl = offer.BookingURL;
                    if (requireRegistration && props.guestMode) {
                        bookingUrl = `/login?redirect_url=${bookingUrl}`;
                    }
                    return (
                        <CardWrapperDiv
                            ref={isLastCard ? inViewRef : null}
                            key={i}
                            isFirstCard={i === 0}
                            isLastCard={isLastCard}
                        >
                            <FlightCard
                                offer={offer}
                                detailsURL={bookingUrl}
                                searchId={props.flightResult.ID}
                                index={i}
                                guestMode={props.guestMode}
                                sendMessage={props.sendMessage}
                                isWidget={props.isWidget}
                                query={props.flightResult.Query}
                                direction={props.direction}
                            />
                        </CardWrapperDiv>
                    );
                })}
                {isLoading && (
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                )}
            </Scroller>
        );
    }
);
