import { captureException } from '@sentry/browser';
import { api } from 'lib/api';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { useUserState } from './UseGlobalState';

export const useChatEventSender = () => {
    const router = useRouter();
    const isGroup = router.pathname.includes('groups/[id]');
    const user = useUserState((state) => state.user);
    const channelHandle = isGroup ? router.query['id'] : user?.id;
    return useCallback(
        async (type: string, properties?: Record<string, string>) => {
            if (typeof channelHandle === 'string') {
                try {
                    await api.trackChatEvent({
                        channelHandle,
                        event: {
                            type,
                            properties,
                        },
                    });
                } catch (err) {
                    captureException(err);
                }
            }
        },
        [router.pathname, router.query, user]
    );
};
