import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const Suitcase = () => {
    return (
        <AmenityWrapper>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path
                        d="M9.1 0.75C9.4864 0.75 9.8 1.05489 9.8 1.43056V2.79167H13.3C13.6864 2.79167 14 3.09656 14 3.47222V12.3194C14 12.6951 13.6864 13 13.3 13H0.7C0.3136 13 0 12.6951 0 12.3194V3.47222C0 3.09656 0.3136 2.79167 0.7 2.79167H4.2V1.43056C4.2 1.05489 4.5136 0.75 4.9 0.75H9.1ZM4.2 4.15278H2.8V11.6389H4.2V4.15278ZM11.2 4.15278H9.8V11.6389H11.2V4.15278ZM8.4 2.11111H5.6V2.79167H8.4V2.11111Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="14" height="14" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </AmenityWrapper>
    );
};
