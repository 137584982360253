import { Spinner } from 'components/icons/Spinner';
import { Scroller } from 'components/organisms/Scroller';
import React from 'react';
import styled from 'styled-components/native';

import { useCarouselHandler } from '../hooks/UseCarouselHandler';
import { useRequireRegistrationState } from '../hooks/UseGlobalState';
import {
    AccommodationOffer,
    AccommodationSearchResult,
    ISendMessageOptions,
    TextDirection,
} from '../types';
import { AccommodationCard } from './AccommodationCard';
import { CardWrapperDiv } from './Carousel';

const SpinnerWrapper = styled.View({
    paddingRight: '16px',
    display: 'flex',
    justifyContent: 'center',
});

export const AccommodationCarousel = React.memo(
    (props: {
        accommodationResult: AccommodationSearchResult;
        guestMode: boolean;
        sendMessage: (text: string, options: ISendMessageOptions) => void;
        isWidget: boolean;
        direction: TextDirection;
    }) => {
        const { offers, inViewRef, isLoading } = useCarouselHandler(
            props.accommodationResult.SelectedOffers,
            props.accommodationResult.ID
        );
        const accommodationOffers = offers as Array<AccommodationOffer>;
        const requireRegistration = useRequireRegistrationState(
            (state) => state.requireRegistration
        );
        return (
            <Scroller leftButtonOffset={0} step={272} verticalCenter="24%">
                {accommodationOffers.map((offer, i) => {
                    const isLastCard = accommodationOffers.length - 1 === i;
                    let bookingUrl = offer.booking_url;
                    if (requireRegistration && props.guestMode) {
                        bookingUrl = `/login?redirect_url=${bookingUrl}`;
                    }
                    return (
                        <CardWrapperDiv
                            ref={isLastCard ? inViewRef : null}
                            key={offer.external_id}
                            isFirstCard={i === 0}
                            isLastCard={isLastCard}
                        >
                            <AccommodationCard
                                imageUrl={offer.thumbnail_url || offer.images[0]}
                                detailsURL={bookingUrl}
                                searchQuery={props.accommodationResult.Query}
                                offer={offer}
                                searchId={props.accommodationResult.ID}
                                index={i}
                                guestMode={props.guestMode}
                                sendMessage={props.sendMessage}
                                isWidget={props.isWidget}
                                direction={props.direction}
                            />
                        </CardWrapperDiv>
                    );
                })}
                {isLoading && (
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                )}
            </Scroller>
        );
    }
);
