import { Autocomplete } from 'components/modules/autocomplete/Autocomplete';
import { useStore } from 'lib/store';
import { GroupUser, User } from 'lib/types';
import React from 'react';
import styled from 'styled-components';

import { carouselProcessor } from '../processors/carousel';
import { hintProcessor } from '../processors/hints';
import { userIdProcessor } from '../processors/userId';
import { ConversationItem, Expectation } from '../types';
import { ChatFeed } from './ChatFeed';
import TextRow from './TextRow';

interface IChatWrapperProps {
    roundTopCorners: boolean;
}

interface ChatProps {
    messages: Array<ConversationItem>;
    sendMessage: (message: string) => void;
    user?: User;
    groupUsers?: Array<GroupUser>;
    channelHandle?: string;
}

const ChatWindow = styled.div({
    width: '100%',
    height: '100%',
    display: 'flex',
    // targets safari by feature
    '@media not all and (min-resolution:.001dpcm)': {
        '@supports (-webkit-appearance:none)': {
            overflowY: 'hidden',
        },
    },
});

const ChatFeedWrapper = styled.div<IChatWrapperProps>((props) => {
    return {
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        background: '#fff',
        border: '1px solid #e9eef2',
        borderTopLeftRadius: `${props.roundTopCorners ? '5px' : '0'}`,
        borderTopRightRadius: `${props.roundTopCorners ? '5px' : '0'}`,
        overflow: 'hidden',
    };
});

export const ChatFeedOverlayParent = styled.div({
    position: 'relative',
    zIndex: 1000000,
});
export const ChatFeedOverlay = styled.div({
    position: 'absolute',
    backgroundColor: '#fff',
    bottom: 0,
    width: '100%',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
});

const Chat = ({ messages, sendMessage, user, groupUsers, channelHandle }: ChatProps) => {
    const isWidget = useStore((store) => store.isWidget);
    const lastMessage = messages[messages.length - 1];
    const languageCode = useStore((store) => store.languageCode);
    const autocompleteActive =
        languageCode === 'en' &&
        !!lastMessage?.bot?.expectation &&
        lastMessage.bot.expectation === Expectation.Location;

    return (
        <>
            <ChatFeedWrapper roundTopCorners={!isWidget}>
                <ChatWindow>
                    <ChatFeed
                        messages={messages}
                        sendMessage={sendMessage}
                        messageProcessors={[hintProcessor, carouselProcessor, userIdProcessor]}
                        user={user}
                        groupUsers={groupUsers}
                        channelHandle={channelHandle}
                    />
                </ChatWindow>
                {/* TODO: @see https://eddytravels.atlassian.net/browse/DEV-1123 */}
                {autocompleteActive ? (
                    <ChatFeedOverlayParent>
                        <ChatFeedOverlay>
                            <Autocomplete />
                        </ChatFeedOverlay>
                    </ChatFeedOverlayParent>
                ) : null}
            </ChatFeedWrapper>
            <TextRow sendMessage={sendMessage} autocompleteActive={autocompleteActive} />
        </>
    );
};

export default Chat;
