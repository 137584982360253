import React from 'react';
import styled from 'styled-components';

interface Props {
    index?: number;
    width?: number;
    height?: number;
    photoUrl?: string;
    borderRadius?: string;
}

const AvatarWrapper = styled.div<Props>(({ width = 28, height = 28, borderRadius = '50%' }) => ({
    display: 'inline-flex',
    height: height,
    width: width,
    borderRadius: borderRadius,
    justifyContent: 'center',
}));

const AvatarContainer = styled.div<Props>(({ index = 0, theme }) => ({
    background: avatarColors[index % (avatarColors.length - 1)],
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '28px',
    color: theme.colors.textLight,
    textShadow: '0px 0px 4px rgba(0, 0, 0, 0.05)',
    textTransform: 'lowercase',
    borderRadius: 'inherit',
    width: 'inherit',
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
}));

const AvatarText = styled.span({
    alignSelf: 'center',
});

const AvatarPhoto = styled.div<Props>(({ photoUrl = '' }) => ({
    background: `url("${photoUrl}")`,
    backgroundSize: 'cover',
}));
const avatarColors = ['#63BBEC', '#9397F8', '#81D8A6', '#D881DA', '#FF8388'];

const UserAvatar: React.FC<Props> = (props) => {
    return (
        <AvatarWrapper {...props}>
            {props.photoUrl ? (
                <AvatarPhoto {...props} />
            ) : (
                <AvatarContainer {...props}>
                    <AvatarText>{props.children}</AvatarText>
                </AvatarContainer>
            )}
        </AvatarWrapper>
    );
};

export default UserAvatar;
