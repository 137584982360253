import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import styled from 'styled-components';

import { CarHireOffer } from '../chat/types';
import { ScaledImage } from './accommodation';
import { VanillaCarRentalsTemplate } from './cars/vanilla';
import { CarDoor, Person, Suitcase } from './icons/amenities';

const AmenityCount = styled.div<{ width?: string }>((props) => ({
    width: props.width || '24px',
    height: '32px',
    fontFamily: props.theme.fonts.default,
    fontSize: '32px',
    color: '#FFFFFF',
    verticalAlign: 'super',
}));

export const CarRentalTemplate = (props: { offer: CarHireOffer; scale: number }) => {
    const offer = props.offer;
    let amenities: Array<(key: number) => JSX.Element> = [];
    if (!isNaN(Number(offer.seats))) {
        amenities = amenities.concat([Person, () => <AmenityCount>{offer.seats}</AmenityCount>]);
    }
    if (offer.bags && offer.bags !== '0') {
        amenities = amenities.concat([
            Suitcase,
            () => (
                <AmenityCount width={offer.bags.length > 1 ? '54px' : '24px'}>
                    {offer.bags}
                </AmenityCount>
            ),
        ]);
    }
    if (offer.doors) {
        amenities = amenities.concat([
            CarDoor,
            () => (
                <AmenityCount width={offer.doors.length > 1 ? '54px' : '24px'}>
                    {offer.doors}
                </AmenityCount>
            ),
        ]);
    }

    return (
        <ScaledImage scale={props.scale}>
            {VanillaCarRentalsTemplate({
                price: offer.total_price,
                imageUrl: offer.image_url,
                currencySymbol: getSymbolFromCurrency(offer.currency),
                durationDays: offer.duration_days,
                amenityComponents: amenities,
                rating: offer.average_rating > 0 ? offer.average_rating?.toFixed(1) : undefined,
                ratingAmount: offer.num_ratings,
            })}
        </ScaledImage>
    );
};
