import {
    FlightOffer,
    FlightSearchQuery,
    ISendMessageOptions,
    Leg,
    MessageOfferType,
    Segment,
    TextDirection,
} from 'components/chat/types';
import ShareButton from 'components/chat/ui/ShareButton';
import { CardLink } from 'components/molecules/link';
import { SubtitleText } from 'components/molecules/text';
import Card from 'components/organisms/card/Card';
import { FlightTemplate } from 'components/templates';
import { ClickedOnFlightOfferEvent, Event, sendEvent } from 'lib/events';
import { GTM_EVENTS } from 'lib/gtmEvents';
import { DateTime } from 'luxon';
import getConfig from 'next/config';
import React, { memo } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Likes } from './Likes';

const { publicRuntimeConfig } = getConfig();

const renderStops = (segments: Array<Segment>) => {
    const stops = segments.length - 1;
    if (stops === 1) {
        return `(${stops} stop)`;
    } else if (stops > 1) {
        return `(${stops} stops)`;
    }
};

const renderDuration = (duration: number) => {
    let hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    if (hours > 10) {
        if (minutes > 30) {
            hours = hours + 1;
        }
        return `${hours}h`;
    } else {
        let segments = [];

        if (hours > 0) {
            segments.push(`${hours}h`);
        }

        if (minutes > 0) {
            segments.push(`${minutes}m`);
        }

        return segments.join(' ');
    }
};

const renderDateTime = (datetime: string) => {
    return DateTime.fromISO(datetime, { zone: 'utc' }).toFormat('ccc d LLL, HH:mm');
};

const legDesc = (prefix: string, leg: Leg) => {
    return (
        <SubtitleText>
            {prefix} {renderDateTime(leg.DepartureTime)} • {renderDuration(leg.Duration)}{' '}
            {leg.Segments?.length > 1 ? `${renderStops(leg.Segments)}\n` : '\n'}
        </SubtitleText>
    );
};

const Subtitle_DEPRECATED = (offer: FlightOffer, direction?: TextDirection) => (
    <View style={{ paddingBottom: '0.25rem' }}>
        {legDesc('→', offer.OutboundLeg)}
        {offer.InboundLeg && legDesc('←', offer.InboundLeg)}
    </View>
);

const Subtitle = (subtitle: string, direction?: TextDirection) => (
    <View style={{ paddingBottom: '0.25rem' }}>
        <SubtitleText direction={direction}>{subtitle}</SubtitleText>
    </View>
);

const handleSharePress = () =>
    TagManager.dataLayer({
        dataLayer: {
            event: GTM_EVENTS.FLIGHT_SHARE,
        },
    });

const handleShareCallback = () =>
    TagManager.dataLayer({
        dataLayer: {
            event: GTM_EVENTS.CONTINUED_FLIGHT_SHARE,
        },
    });

const handleClickedOnFlight = (offer: FlightOffer, query?: FlightSearchQuery) => {
    const clickedOnFlightEvent: ClickedOnFlightOfferEvent = {
        type: Event.CLICKED_ON_FLIGHT_OFFER,
        provider: offer.ProviderName,
        departure_location: query?.OriginNameEn,
        departure_date: query?.DepartureDate?.Start,
        destination_location: query?.DestinationNameEn,
        return_date: query?.ReturnDate?.End,
        outbound_leg: {
            airlines: offer.OutboundLeg.Airlines,
            destination_airport: offer.OutboundLeg.Destination.Code,
            origin_airport: offer.OutboundLeg.Origin.Code,
        },
        children_passengers: offer.ChildrenPassengers,
        infant_passengers: offer.InfantPassengers,
        adult_passengers: offer.AdultPassengers,
        cabin_class: offer.CabinClass,
        currency: offer.Currency,
        price: offer.Price,
    };
    if (offer.InboundLeg) {
        clickedOnFlightEvent.inbound_leg = {
            airlines: offer.InboundLeg.Airlines,
            destination_airport: offer.InboundLeg.Destination.Code,
            origin_airport: offer.InboundLeg.Origin.Code,
        };
    }
    sendEvent(clickedOnFlightEvent);
    TagManager.dataLayer({
        dataLayer: {
            event: GTM_EVENTS.CLICKED_ON_FLIGHT,
        },
    });
};

interface FlightCardProps {
    offer: FlightOffer;
    detailsURL: string;
    searchId: string;
    index: number;
    guestMode: boolean;
    sendMessage: (text: string, options: ISendMessageOptions) => void;
    isWidget: boolean;
    query?: FlightSearchQuery;
    direction: TextDirection;
}
export const FlightCard = memo(
    ({
        offer,
        detailsURL,
        searchId,
        index,
        guestMode,
        sendMessage,
        isWidget,
        query,
        direction,
    }: FlightCardProps) => {
        const { t } = useTranslation('cards');

        const highlight = offer.Tags?.[0] === t('flight.best-value');
        return (
            <>
                <Card
                    onPress={() => handleClickedOnFlight(offer, query)}
                    imageUrl={offer.ThumbnailURL}
                    title={offer.title}
                    subtitle={
                        offer.subtitle
                            ? Subtitle(offer.subtitle, direction)
                            : Subtitle_DEPRECATED(offer, direction)
                    }
                    url={detailsURL}
                    image={
                        <FlightTemplate
                            offer={offer}
                            scale={0.328}
                            highlight={highlight}
                            direction={direction}
                        />
                    }
                    primaryButton={
                        !isWidget ? (
                            <ShareButton
                                displayCallback={handleSharePress}
                                title={offer.title}
                                url={getShareUrl(offer, searchId, index)}
                                successCallback={handleShareCallback}
                                guestMode={guestMode}
                            />
                        ) : undefined
                    }
                    secondaryButton={
                        <CardLink
                            href={`${detailsURL}?btn=details`}
                            onPress={() => handleClickedOnFlight(offer, query)}
                            highlight={highlight}
                        >
                            {t('flight.view-details')}
                        </CardLink>
                    }
                    paddingBottom={offer.id ? '1.25rem' : '0.75rem'}
                    direction={direction}
                />
                {offer.id ? (
                    <Likes
                        offerId={offer.id}
                        sendMessage={sendMessage}
                        offerType={MessageOfferType.Flights}
                    />
                ) : null}
            </>
        );
    }
);

const getShareUrl = (offer: FlightOffer, searchID: string, index: number) => {
    if (offer.id) {
        return `${publicRuntimeConfig?.rootUrl}/share/flights/${offer.id}`;
    } else return `${publicRuntimeConfig?.rootUrl}/share/flights/${searchID}-${index}`;
};
