import { useStore } from 'lib/store';
import { GroupUser } from 'lib/types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import styled from 'styled-components';

import { useMessagesState, useScrollToBottomState } from '../hooks/UseGlobalState';
import { IChatFeed } from '../types';
import { ChatMessage } from './ChatMessage';

const ChatPanel = styled.div({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // targets safari by feature - for hidden scrolling
    '@media not all and (min-resolution:.001dpcm)': {
        '@supports (-webkit-appearance:none)': {
            width: 0,
            background: 'transparent',
        },
    },
});

const ChatWindowHeader = () => <div style={{ paddingTop: 8 }} />;

export const ChatFeed = (props: IChatFeed) => {
    const textDirection = useStore((store) => store.textDirection);

    const chatRef = useRef<VirtuosoHandle>(null);
    const scrollToBottom = (delayMillis?: number) => {
        if (chatRef.current) {
            setTimeout(() => {
                chatRef.current?.scrollTo?.({ top: Number.MAX_SAFE_INTEGER });
            }, delayMillis || 100);
        }
    };
    const externalScrollToBottom = useScrollToBottomState((state) => state.scrollToBottom);
    const setExternalScrollToBottom = useScrollToBottomState((state) => state.setScrollToBottom);
    useEffect(() => {
        if (externalScrollToBottom) {
            scrollToBottom();
            setExternalScrollToBottom(false);
        }
    }, [externalScrollToBottom]);
    const loadMessages = useMessagesState((state) => state.loadMessages);
    const firstItemIndex = useMessagesState((state) => state.firstItemIndex);
    const loadMore = useCallback(async () => {
        if (props.channelHandle) {
            loadMessages(props.channelHandle);
        }
    }, [props.channelHandle]);

    useEffect(() => {
        if (props.channelHandle) {
            loadMessages(props.channelHandle);
        }
    }, [props.channelHandle]);

    const lastMessageIndex = firstItemIndex + props.messages.length - 1;

    const processedMessages = useMemo(
        () =>
            props.messages.map((message) => {
                if (props.messageProcessors) {
                    props.messageProcessors.forEach((processor) => {
                        processor(message);
                    });
                }
                return message;
            }),
        [props.messages]
    );

    const [chatPanelRef, isChatPanelVisible] = useInView({
        threshold: 0.6,
    });

    useEffect(() => {
        if (isChatPanelVisible) {
            // chat panel was hidden and virtuoso destroyed visible items, need to scroll down manually
            scrollToBottom();
        }
    }, [isChatPanelVisible]);

    useEffect(() => {
        scrollToBottom();
        scrollToBottom(200);
    }, [processedMessages.length]);

    return (
        <ChatPanel ref={chatPanelRef}>
            <Virtuoso
                ref={chatRef}
                startReached={loadMore}
                firstItemIndex={firstItemIndex}
                totalCount={props.messages.length}
                components={{ Header: ChatWindowHeader }}
                data={processedMessages}
                alignToBottom
                followOutput
                itemContent={(index, message) => (
                    <ChatMessage
                        direction={textDirection}
                        message={message}
                        sendMessage={props.sendMessage}
                        groupUsers={props.groupUsers as GroupUser[]}
                        user={props.user}
                        isLastMessage={index === lastMessageIndex}
                        nextMessage={processedMessages[index - firstItemIndex + 1]}
                    />
                )}
            />
        </ChatPanel>
    );
};
