import { AccommodationDeal, AccommodationDealType, TextDirection } from 'components/chat/types';
import {
    AmenitiesWrapper,
    CurrencyLabel,
    DurationLabel,
    PriceLabel,
    PriceLabelContainer,
    Wrapper,
    WrapperContainer,
} from 'components/templates/common';
import { DisinfectedLabel } from 'components/templates/icons/DisinfectedLabel';
import React from 'react';
import styled from 'styled-components';

import { BlackFriday } from './deals/BlackFriday';
import { Sale } from './deals/Sale';

const dealTypesMap: {
    [key: string]: (props: AccommodationDeal) => JSX.Element;
} = {
    [AccommodationDealType.Sale]: Sale,
    [AccommodationDealType.BlackFriday]: BlackFriday,
};

const DisinfectedLabelContainer = styled.div({
    position: 'absolute',
    maxWidth: '200px',
    maxHeight: '71px',
    top: '33px',
    left: '30px',
    display: 'flex',
    flexDirection: 'column',
});

const RatingContainer = styled.div({
    position: 'absolute',
    width: '88px',
    height: '48px',
    bottom: '30px',
    left: '30px',
    border: '3px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const RatingLabel = styled.div((props) => ({
    position: 'absolute',
    width: '82px',
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '36px',
    display: 'flex',
    justifyContent: 'center',
    color: '#FFFFFF',
}));

const RatingDescriptionLabel = styled.div((props) => ({
    position: 'absolute',
    bottom: '40px',
    left: '136px',
    width: '160px',
    height: '29px',
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '29px',
    lineHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
}));

interface IProps {
    price: number;
    rating?: number;
    ratingDescription: string;
    amenityComponents: Array<(key: number) => JSX.Element>;
    disinfected: boolean;
    imageUrl: string;
    durationNights: number;
    currencySymbol: string;
    deal?: AccommodationDeal;
    durationLabel: string;
    direction: TextDirection;
}

export const VanillaAccommodationTemplate = (props: IProps) => {
    const isRtl = props.direction === 'rtl';
    const price = Intl.NumberFormat('en-US').format(props.price);
    const currencySymbol = <CurrencyLabel>{props.currencySymbol}</CurrencyLabel>;
    return (
        <WrapperContainer>
            <Wrapper imageUrl={props.imageUrl}>
                {props.disinfected ? (
                    <DisinfectedLabelContainer>
                        <DisinfectedLabel />
                    </DisinfectedLabelContainer>
                ) : null}
                {props.deal && dealTypesMap[props.deal.deal_type](props.deal)}
                <PriceLabelContainer top={props.deal ? 122 : 33}>
                    <PriceLabel>
                        {isRtl ? [price, currencySymbol] : [currencySymbol, price]}
                    </PriceLabel>
                    <DurationLabel>
                        {props.durationLabel ||
                            `for ${props.durationNights} ${
                                props.durationNights === 1 ? 'night' : 'nights'
                            }`}
                    </DurationLabel>
                </PriceLabelContainer>
                {props.rating ? (
                    <RatingContainer>
                        <RatingLabel>{props.rating}</RatingLabel>
                    </RatingContainer>
                ) : null}
                <AmenitiesWrapper>{props.amenityComponents.map((a, i) => a(i))}</AmenitiesWrapper>
                <RatingDescriptionLabel>{props.ratingDescription}</RatingDescriptionLabel>
            </Wrapper>
        </WrapperContainer>
    );
};
