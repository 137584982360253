import { TextDirection } from 'components/chat/types';
import { Plane } from 'components/icons/Plane';
import React from 'react';
import styled, { useTheme } from 'styled-components';

const FlightCard = styled.div((props) => ({
    userSelect: 'none',
    background: props.theme.colors.flightCardBackground,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '800px',
    height: '421px',
    position: 'absolute',
    padding: '0 29px 5px 29px',
    boxSizing: 'border-box',
    direction: 'ltr',
}));

const Header = styled.div({
    display: 'flex',
});

const Tag = styled.div((props) => ({
    flexDirection: 'column',
    textTransform: 'uppercase',
    fontSize: '28px',
    paddingTop: '38px',
    letterSpacing: '3.23px',
    color: props.theme.colors.textLight,
    fontFamily: 'Eina01-Bold',
    flexGrow: 1,
}));

const Price = styled.div((props) => ({
    fontFamily: 'Eina01-Bold',
    letterSpacing: '.22px',
    fontSize: '54px',
    marginTop: '18px',
    padding: '0 5px 0 0',
    color: props.theme.colors.textLight,
}));

const CurrencySymbol = styled(Price)({
    float: 'left',
    fontSize: '32px',
    marginTop: '36px',
});

const Ticket = styled.div({
    height: '330px',
    backgroundImage: 'url(/images/flight/rectangle.svg)',
    backgroundRepeat: 'no-repeat',
});

const TicketContent = styled.div({
    padding: '20px 0',
});

const TimeAndCodeContainer = styled.div({
    display: 'inline-block',
    paddingLeft: '50px',
    paddingRight: '50px',
});

const Time = styled.div({
    fontSize: '53px',
    letterSpacing: '.22px',
    color: '#000000',
    fontFamily: 'Eina01-SemiBold',
    textAlign: 'center',
});

const IATACode = styled.div({
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '2.6px',
    fontFamily: 'Eina01-Regular',
});

const FlightLegContainer = styled.div({
    paddingTop: '10px',
    display: 'flex',
    paddingBottom: '10px',
});

const RouteContainer = styled.div({
    display: 'inline-block',
    width: '140px',
});

const PlaneIconWrapper = styled.div({
    margin: '0 auto',
    maxWidth: '45px',
});

const TripVisual = styled.div({
    height: '30px',
    width: '80%',
    position: 'relative',
});

const TripStops = styled.div({
    position: 'absolute',
    height: '30px',
    width: '100%',
    left: '10%',
    top: 6,
});

const TripStop = styled.div<{ width: number }>((props) => ({
    display: 'inline-block',
    textAlign: 'center',
    width: `${props.width}%`,
}));

const FlightLine = styled.div({
    height: '100%',
    width: '140px',
    backgroundImage: 'url(/images/flight/flight-line.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
});

const CarrierWrapper = styled.div({
    paddingRight: '15px',
    maxWidth: '200px',
    position: 'relative',
});

const CarrierIcon = styled.img({
    position: 'absolute',
    bottom: '25%',
});

interface IFlightsTemplateProps {
    tag?: string;
    price: number;
    currencySymbol: string;
    outboundLeg: IFlightLeg;
    inboundLeg?: IFlightLeg;
    highlight?: boolean;
    direction: TextDirection;
}

interface IFlightLeg {
    departureTime: string;
    departureAITA: string;
    arrivalTime: string;
    arrivalIATA: string;
    stops?: number;
    carrierCode: string;
}

const FlightLeg: React.FC<{
    leg: IFlightLeg;
    reverseFlight?: boolean;
    direction: TextDirection;
}> = (props) => {
    const theme = useTheme();
    const isRtl = props.direction === 'rtl';
    return (
        <FlightLegContainer>
            <TimeAndCodeContainer>
                <Time>{isRtl ? props.leg.arrivalTime : props.leg.departureTime}</Time>
                <IATACode>{isRtl ? props.leg.arrivalIATA : props.leg.departureAITA}</IATACode>
            </TimeAndCodeContainer>
            <RouteContainer>
                <PlaneIconWrapper>
                    <Plane
                        color={theme.colors.flightCardBackground}
                        reversed={isRtl ? !props.reverseFlight : props.reverseFlight}
                    />
                </PlaneIconWrapper>
                <TripVisual>
                    <FlightLine />
                    {props.leg.stops !== null && (
                        <TripStops>
                            {Array.from(Array(props.leg.stops).keys()).map((i) => (
                                <TripStop width={100 / (props.leg.stops || 1)} key={i}>
                                    <img src="/images/flight/flight-stop.svg" />
                                </TripStop>
                            ))}
                        </TripStops>
                    )}
                </TripVisual>
            </RouteContainer>
            <TimeAndCodeContainer>
                <Time>{isRtl ? props.leg.departureTime : props.leg.arrivalTime}</Time>
                <IATACode>{isRtl ? props.leg.departureAITA : props.leg.arrivalIATA}</IATACode>
            </TimeAndCodeContainer>
            <CarrierWrapper>
                <CarrierIcon
                    src={`https://images.kiwi.com/airlines/64/${props.leg.carrierCode}.png`}
                />
            </CarrierWrapper>
        </FlightLegContainer>
    );
};

export const VanillaFlightTemplate: React.FC<IFlightsTemplateProps> = (
    props: IFlightsTemplateProps
) => {
    const isAED = props.currencySymbol === 'د.إ';
    const isRtl = props.direction === 'rtl';
    const price = <Price>{props.price}</Price>;
    const currencySymbol = <CurrencySymbol>{props.currencySymbol}</CurrencySymbol>;
    return (
        <FlightCard>
            <Header>
                <Tag>{props.tag}</Tag>
                {isAED && !isRtl ? [price, currencySymbol] : [currencySymbol, price]}
            </Header>
            <Ticket>
                <TicketContent>
                    <FlightLeg leg={props.outboundLeg} direction={props.direction} />
                    {props.inboundLeg && (
                        <FlightLeg
                            leg={props.inboundLeg}
                            reverseFlight
                            direction={props.direction}
                        />
                    )}
                </TicketContent>
            </Ticket>
        </FlightCard>
    );
};
