import { TextDirection } from 'components/chat/types';
import styled from 'styled-components';
export const SubtitleText = styled.div<{ direction?: TextDirection }>((props) => ({
    fontFamily: `${props.theme.fonts.default}`,
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '14px',
    color: props.theme.colors.darkgrey,
    paddingBottom: 4,
    direction: props.direction,
    display: 'inline',
    whiteSpace: 'pre-wrap',
}));
