import { Scroller } from 'components/organisms/Scroller';
import { useStore } from 'lib/store';
import { CalendarLocales } from 'locales';
import { DateTime, DateTimeFormatOptions, Info, Interval, LocaleOptions } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useChatInputState, useScrollToBottomState } from '../hooks/UseGlobalState';

const TOTAL_CALENDAR_MONTHS = 24;

const getDaysInMonth = (year: number, month: number) =>
    DateTime.fromObject({ year, month }).daysInMonth;

const getFirstDayOfTheWeek = (year: number, month: number) =>
    DateTime.fromObject({ year, month, day: 1 }).weekday;

const getCalendarMonths = (monthsToDisplay: number) => {
    const currentDate = DateTime.local();
    return Array.from({ length: monthsToDisplay }, (x, i) => {
        const year = currentDate.year + Math.floor((i + currentDate.month - 1) / 12);
        let month = (currentDate.month + i) % 12;
        if (month === 0) {
            month = 12;
        }
        return {
            year,
            month,
        };
    });
};

const formatDates = (locale: string, dateFrom: DateTime, dateTo?: DateTime) => {
    let dateFormat = 'LLLL d';
    if (locale === 'ar-AE') {
        dateFormat = 'd LLLL';
    }

    if (dateTo) {
        if (dateFrom.month === dateTo.month) {
            if (locale === 'ar-AE') {
                return `${dateFrom.setLocale(locale).toFormat('d')} - ${dateTo
                    .setLocale(locale)
                    .toFormat(dateFormat)}`;
            }
            return `${dateFrom.setLocale(locale).toFormat(dateFormat)} - ${dateTo
                .setLocale(locale)
                .toFormat('d')}`;
        }
        return `${dateFrom.setLocale(locale).toFormat(dateFormat)} - ${dateTo
            .setLocale(locale)
            .toFormat(dateFormat)}`;
    } else {
        return `${dateFrom.setLocale(locale).toFormat(dateFormat)}`;
    }
};

const Calendar = styled.div((props) => ({
    boxSizing: 'border-box',
    border: '1px solid #E9EEF2',
    borderRadius: props.theme.borderRadius.cards,
    marginLeft: 10,
    width: 332,
    height: 280,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
    marginTop: '5px',
    marginBottom: '10px',
}));

const Weekday = styled.div((props) => ({
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#7C8B99',
    '&:nth-child(n+6)': {
        color: props.theme.colors.text,
    },
}));

const CalendarGrid = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 40px)',
    paddingBottom: 5,
});

const Title = styled.div((props) => ({
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
    textAlign: 'center',
    paddingBottom: 5,
    ':first-letter': {
        textTransform: 'capitalize',
    },
}));

const Day = styled.button<{
    gridColumn?: number;
    isPastDay: boolean;
    isWeekend: boolean;
    isSelected: boolean;
    isInRange: boolean;
    disabled: boolean;
}>((props) => ({
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 400,
    gridColumn: props.gridColumn,
    textAlign: 'center',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '40px',
    color: props.isPastDay
        ? '#D8DCE0'
        : props.isSelected
        ? props.theme.colors.textLight
        : props.theme.colors.text,
    width: 40,
    height: 36,
    borderRadius: props.isSelected ? 4 : 0,
    background: props.isSelected
        ? props.theme.colors.primary
        : props.isInRange
        ? props.theme.colors.primaryLight
        : props.isWeekend
        ? props.theme.colors.lightgrey
        : props.theme.colors.background,
    cursor: props.disabled ? 'arrow' : 'pointer',
}));

const Month = ({
    year,
    month,
    dateFrom,
    dateTo,
    onClick,
}: {
    year: number;
    month: number;
    dateFrom?: DateTime;
    dateTo?: DateTime;
    onClick: (date: DateTime) => void;
}) => {
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfWeek = getFirstDayOfTheWeek(year, month);
    const currentDay = DateTime.local();
    const isMonthInPast =
        currentDay.year > year || (currentDay.year === year && currentDay.month > month);
    const isCurrentMonth = currentDay.year === year && currentDay.month === month;
    return (
        <CalendarGrid>
            {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day, i) => {
                const isPastDay = isMonthInPast || (isCurrentMonth && currentDay.day > day);
                const dateTime = DateTime.fromObject({ year, month, day: day });
                const isWeekend = dateTime.weekday === 6 || dateTime.weekday === 7;
                const isInRange = !!(
                    dateFrom &&
                    dateTo &&
                    Interval.fromDateTimes(dateFrom, dateTo).contains(dateTime)
                );
                const isSelected = !!(dateFrom?.equals(dateTime) || dateTo?.equals(dateTime));
                return (
                    <Day
                        key={i}
                        disabled={currentDay.startOf('day') > dateTime}
                        gridColumn={i === 0 ? firstDayOfWeek : undefined}
                        isPastDay={isPastDay}
                        isWeekend={isWeekend}
                        isSelected={isSelected}
                        isInRange={isInRange}
                        onClick={() => onClick(dateTime)}
                    >
                        {day}
                    </Day>
                );
            })}
        </CalendarGrid>
    );
};

const Header = ({ month, year, locale }: { month: number; year: number; locale: string }) => {
    return <Title>{`${DateTime.fromObject({ year, month }, { locale }).monthLong} ${year}`}</Title>;
};

const Weekdays = ({ locale }: { locale: string }) => {
    return (
        <CalendarGrid>
            {Info.weekdays('short', { locale }).map((day) => (
                <Weekday key={day}>{day.toUpperCase()}</Weekday>
            ))}
        </CalendarGrid>
    );
};

export const CalendarCarousel = () => {
    const setInput = useChatInputState((state) => state.setInput);
    const [dateFrom, setDateFrom] = useState<DateTime>();
    const [dateTo, setDateTo] = useState<DateTime>();
    const languageCode = useStore((store) => store.languageCode);
    const locale = CalendarLocales[languageCode] || 'en-US';
    const onClick = useCallback(
        (dateTime: DateTime) => {
            if (!dateFrom) {
                setDateFrom(dateTime);
                setInput(formatDates(locale, dateTime));
            } else if (dateTo) {
                if (dateTime > dateTo) {
                    setDateTo(dateTime);
                    setInput(formatDates(locale, dateFrom, dateTime));
                } else {
                    setDateFrom(dateTime);
                    setDateTo(undefined);
                    setInput(formatDates(locale, dateTime));
                }
            } else if (dateFrom > dateTime) {
                setDateFrom(dateTime);
                setDateTo(undefined);
                setInput(formatDates(locale, dateTime));
            } else {
                setDateTo(dateTime);
                setInput(formatDates(locale, dateFrom, dateTime), true);
            }
        },
        [dateFrom, dateTo]
    );
    const setScrollToBottom = useScrollToBottomState((state) => state.setScrollToBottom);
    useEffect(() => {
        setScrollToBottom(true);
    }, []);
    return (
        <Scroller leftButtonOffset={0} step={175} verticalCenter="50%">
            {getCalendarMonths(TOTAL_CALENDAR_MONTHS).map((range) => (
                <Calendar key={`${range.year}-${range.month}`}>
                    <Header year={range.year} month={range.month} locale={locale} />
                    <Weekdays locale={locale} />
                    <Month
                        year={range.year}
                        month={range.month}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        onClick={onClick}
                    />
                </Calendar>
            ))}
        </Scroller>
    );
};
