import { api } from 'lib/api';
import { handleChatMessageEvents } from 'lib/events';
import { handleGTMEvents } from 'lib/gtmEvents';
import { useStore } from 'lib/store';
import { useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import useWebSocket, { Options, ReadyState } from 'react-use-websocket';

import { ConversationItem, Expectation, ISendMessageOptions, MessageType } from '../types';
import { useActiveElement } from './UseActiveElement';
import { useChannelLikesState, useGroupState, useMessagesState } from './UseGlobalState';

const OPTIONS: Options = {
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 5000,
};

export const useChatWebSocket = (
    authenticated: boolean,
    assistantDisabled: boolean,
    groupID?: string,
    token?: string
    // eslint-disable-next-line max-params
) => {
    const messages = useMessagesState((state) => state.messages);
    const appendMessages = useMessagesState((state) => state.appendMessages);

    if (token) {
        OPTIONS['protocols'] = [token];
    }
    const partnerId = useStore((store) => store.partnerId);
    const { sendMessage, lastMessage, readyState } = useWebSocket(
        getChatUrl(groupID),
        OPTIONS,
        authenticated
    );
    const activeElement = useActiveElement();
    const like = useChannelLikesState((state) => state.like);
    const unlike = useChannelLikesState((state) => state.unlike);
    const setGroupUsers = useGroupState((state) => state.setGroupUsers);
    const setLanguageCode = useStore((store) => store.setLanguageCode);
    useEffect(() => {
        if (lastMessage !== null) {
            const message: ConversationItem = JSON.parse(lastMessage.data);
            switch (message?.user?.message_type) {
                case MessageType.Like:
                    message.user.offer &&
                        like(message.user.offer.type, message.user.offer.id, message.user.user_id);
                    return;
                case MessageType.Unlike:
                    message.user.offer &&
                        unlike(
                            message.user.offer.type,
                            message.user.offer.id,
                            message.user.user_id
                        );
                    return;
                case MessageType.Read:
                    return;
                case MessageType.JoinedChannel:
                case MessageType.LeftChannel:
                    api.getGroupUsers(String(groupID)).then((response) =>
                        setGroupUsers(response.data)
                    );
                    return;
            }
            if (message?.bot?.message_type === MessageType.SetLanguage) {
                const languageCode = message.bot?.text;
                if (languageCode) {
                    setLanguageCode(languageCode);
                }
                return;
            }
            handleKeyboardOnMessage(activeElement, message);
            handleGTMEvents(message);
            handleChatMessageEvents(
                message,
                messages?.length > 0 ? messages[messages.length - 1] : undefined
            );
            sendMessage(JSON.stringify({ message_type: MessageType.Read }));
            appendMessages([message]);
        }
    }, [lastMessage, sendMessage]);
    const handleSendMessage = useCallback(
        (text: string, options?: ISendMessageOptions) => {
            if (!text && !options?.message_type) {
                return;
            }

            let messageOptions: ISendMessageOptions = {
                assistant_disabled: assistantDisabled,
            };

            if (partnerId) {
                messageOptions.partner_id = partnerId;
            }

            if (typeof options !== 'undefined') {
                messageOptions = {
                    ...messageOptions,
                    ...options,
                };
            }
            sendMessage(JSON.stringify({ text: text, ...messageOptions }));
        },
        [partnerId]
    );

    const connected = readyState === ReadyState.OPEN;

    return {
        messages,
        sendRequest: sendMessage,
        sendMessage: handleSendMessage,
        connected,
    };
};

const getChatUrl = (groupID?: string) => {
    if (typeof window !== 'undefined') {
        let queryString = '';
        if (groupID) {
            queryString = `?group_id=${groupID}`;
        }
        const scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
        return `${scheme}://${window.location.host}/rest/chat${queryString}`;
    }
    return '';
};

const handleKeyboardOnMessage = (activeElement: Element | null, message: ConversationItem) => {
    if (!isMobile) {
        return;
    }
    if (activeElement?.tagName?.toLocaleLowerCase() !== 'span') {
        return;
    }
    const textArea = activeElement as HTMLTextAreaElement;
    if (textArea.innerHTML) {
        return;
    }
    if (
        message.bot?.flight_search_result ||
        message.bot?.accommodation_search_result ||
        message.bot?.car_hire_search_result ||
        message.bot?.carousel ||
        message.bot?.activity_search_result ||
        message.bot?.places ||
        message.bot?.image ||
        message.bot?.expectation === Expectation.Date
    ) {
        textArea.blur();
    } else if (
        message.bot?.expectation === Expectation.Location &&
        message.bot?.hints?.length === 0
    ) {
        textArea.focus();
    }
};
