import useResizeObserver from '@react-hook/resize-observer';
import { ArrowLeft } from 'components/icons/ArrowLeft';
import { ArrowRight } from 'components/icons/ArrowRight';
import React, {
    ComponentPropsWithoutRef,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import styled, { CSSObject, useTheme } from 'styled-components';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const ScrollerContainer = styled.div({
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
});
interface IScrollerWrapperProps {
    centered?: boolean;
    isMobile: boolean;
}
const ScrollerWrapper = styled.div<IScrollerWrapperProps>((props) => {
    const base: CSSObject = {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'hidden',
        justifyContent: props.centered ? 'center' : 'unset',
        zIndex: 1,
        scrollBehavior: 'smooth',
    };
    const mobile: CSSObject = {
        scrollSnapType: 'x mandatory',
        scrollbarWidth: 'none' /* Firefox */,
        msOverflowStyle: 'none' /* IE 10+ */,
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent' /* Chrome/Safari/Webkit */,
            display: 'none',
        },
        overflowX: 'scroll',
    };

    return props.isMobile ? { ...base, ...mobile } : base;
});

const ScrollNav = styled.div({
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
});

interface IScrollButton {
    visible: boolean;
    top?: number | string;
    right?: number;
    left?: number;
}

const ScrollButton = styled.div<IScrollButton>((props) => ({
    position: 'absolute',
    top: props.top ? `${props.top}` : '50%',
    transform: 'translateY(-50%)',
    height: '36px',
    width: '30px',
    background: `${props.theme.colors.background}`,

    outline: 'none',
    cursor: 'pointer',
    zIndex: 2,
    textAlign: 'center',
    lineHeight: '36px',
    fontWeight: 900,
    fontFamily: 'auto',
    display: props.visible ? 'block' : 'none',
    border: `1px solid ${props.theme.colors.darkergrey}`,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.05)',
    '&:hover': {
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    },
    userSelect: 'none',
}));

const ScrollButtonIconWrapper = styled.div({
    paddingTop: '4px',
});

const ScrollRightButton = styled(ScrollButton)((props) => ({
    right: props.right ? props.right : 0,
    borderRight: 'none',
    borderRadius: '4px 0 0 4px',
}));

const ScrollLeftButton = styled(ScrollButton)((props) => ({
    left: props.left ? props.left : 0,
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
}));

interface INavigationState {
    scrollLeft: number;
    scrollWidth: number;
    clientWidth: number;
    showLeftButton: boolean;
    showRightButton: boolean;
    currentLeftButtonOffset?: number;
    currentRightButtonOffset?: number;
}

interface IProps extends ComponentPropsWithoutRef<'div'> {
    leftButtonOffset?: number;
    rightButtonOffset?: number;
    verticalCenter?: number | string;
    step: number;
    centered?: boolean;
}

const MobileScroller = (props: IProps) => {
    return (
        <ScrollerContainer>
            <ScrollerWrapper isMobile={true}>{props.children}</ScrollerWrapper>
        </ScrollerContainer>
    );
};

export const Scroller = (props: IProps) => {
    if (isMobile) {
        return <MobileScroller {...props} />;
    }

    const navigationStep = props.step;
    const leftButtonOffset = props.leftButtonOffset ? props.leftButtonOffset : 0;
    const rightButtonOffset = props.rightButtonOffset ? props.rightButtonOffset : 0;

    const [horNavigation, setHorNavigation] = useState<INavigationState>({
        scrollLeft: 0,
        scrollWidth: 0,
        clientWidth: 0,
        showLeftButton: false,
        showRightButton: false,
        currentLeftButtonOffset: leftButtonOffset,
        currentRightButtonOffset: rightButtonOffset,
    });

    const ref = useRef<HTMLElement | null>(null);
    const setRef = useCallback(
        (node) => {
            if (node) {
                ref.current = node;

                setHorNavigation({
                    ...horNavigation,
                    scrollLeft: ref.current!.scrollLeft,
                    scrollWidth: ref.current!.scrollWidth,
                    clientWidth: (ref.current as HTMLElement).clientWidth,
                });
            }
        },
        [React.Children.count(props.children)]
    );

    useResizeObserver(ref, (entry) => {
        setHorNavigation({
            ...horNavigation,
            scrollLeft: entry.target.scrollLeft,
            scrollWidth: entry.target.scrollWidth,
            clientWidth: (entry.target as HTMLElement).clientWidth,
        });
    });

    const scrollLeft = () => {
        const chatPadding = 8;
        const leftAdjustFirstStep =
            navigationStep - (horNavigation.clientWidth % navigationStep) + chatPadding;

        if (horNavigation.showRightButton === false && horNavigation.scrollLeft > navigationStep) {
            horNavigation.scrollLeft = horNavigation.scrollLeft - leftAdjustFirstStep;
        } else {
            horNavigation.scrollLeft = horNavigation.scrollLeft - navigationStep;
        }

        if (horNavigation.scrollLeft <= 0) {
            horNavigation.scrollLeft = 0;
            horNavigation.currentRightButtonOffset = 0;
            horNavigation.currentLeftButtonOffset = leftButtonOffset;
            horNavigation.showLeftButton = false;
        }

        return setHorNavigation({
            ...horNavigation,
        });
    };

    const scrollRight = () => {
        const overflowDiff =
            horNavigation.scrollWidth - horNavigation.scrollLeft - horNavigation.clientWidth;

        if (overflowDiff > 0 && overflowDiff < navigationStep) {
            horNavigation.scrollLeft = horNavigation.scrollLeft + overflowDiff;
            horNavigation.currentLeftButtonOffset = 0;
            horNavigation.currentRightButtonOffset = leftButtonOffset;

            return setHorNavigation({
                ...horNavigation,
            });
        }

        if (
            horNavigation.scrollLeft + navigationStep + horNavigation.clientWidth >
            horNavigation.scrollWidth
        ) {
            horNavigation.scrollLeft = horNavigation.scrollWidth - horNavigation.clientWidth;
            horNavigation.showRightButton = false;
            return setHorNavigation({
                ...horNavigation,
            });
        }

        horNavigation.scrollLeft = horNavigation.scrollLeft + navigationStep;

        return setHorNavigation({
            ...horNavigation,
        });
    };

    useIsomorphicLayoutEffect(() => {
        const element = ref.current;
        if (!element) {
            return;
        }

        const overflowDiff =
            horNavigation.scrollWidth - horNavigation.scrollLeft - horNavigation.clientWidth;

        horNavigation.showLeftButton = false;
        if (horNavigation.scrollLeft > 0) {
            horNavigation.showLeftButton = true;
        }

        horNavigation.showRightButton = false;

        if (overflowDiff > 0) {
            horNavigation.showRightButton = true;
        }

        setHorNavigation({
            ...horNavigation,
            scrollWidth: element.scrollWidth,
            clientWidth: element.clientWidth,
        });
        element.scrollLeft = horNavigation.scrollLeft;
    }, [ref.current?.scrollWidth, horNavigation.scrollLeft, React.Children.count(props.children)]);

    const theme = useTheme();

    return (
        <ScrollerContainer>
            <ScrollerWrapper
                ref={setRef}
                isMobile={isMobile}
                centered={props.centered ? !horNavigation.showRightButton : false}
            >
                {props.children}
            </ScrollerWrapper>
            <ScrollNav>
                <ScrollLeftButton
                    visible={horNavigation.showLeftButton}
                    top={props.verticalCenter}
                    left={horNavigation.currentLeftButtonOffset}
                    onClick={scrollLeft}
                >
                    <ScrollButtonIconWrapper>
                        <ArrowLeft color={theme.colors.icon} />
                    </ScrollButtonIconWrapper>
                </ScrollLeftButton>
                <ScrollRightButton
                    visible={horNavigation.showRightButton}
                    top={props.verticalCenter}
                    right={horNavigation.currentRightButtonOffset}
                    onClick={scrollRight}
                >
                    <ScrollButtonIconWrapper>
                        <ArrowRight color={theme.colors.icon} />
                    </ScrollButtonIconWrapper>
                </ScrollRightButton>
            </ScrollNav>
        </ScrollerContainer>
    );
};
