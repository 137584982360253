import { CardLink } from 'components/molecules/link';
import { Card } from 'components/organisms/card';
import { CarRentalTemplate } from 'components/templates';
import { ClickedOnCarRentalOfferEvent, Event, sendEvent } from 'lib/events';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
    CarHireOffer,
    CarSearchQuery,
    ISendMessageOptions,
    MessageOfferType,
    TextDirection,
} from '../types';
import { Likes } from './Likes';

const SubtitleLine = styled.div<{ direction?: TextDirection }>((props) => ({
    fontFamily: props.theme.fonts.default,
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '14px',
    color: props.theme.colors.darkgrey,
    paddingBottom: 4,
    direction: props.direction || 'ltr',
}));

const SubtitleWrapper = styled.div({
    height: 58,
});

export const CarRentalCard = ({
    offer,
    bookingUrl,
    query,
    sendMessage,
    direction,
}: {
    offer: CarHireOffer;
    bookingUrl: string;
    query?: CarSearchQuery;
    sendMessage: (text: string, options: ISendMessageOptions) => void;
    direction?: TextDirection;
}) => {
    const { t } = useTranslation('cards');

    return (
        <>
            <Card
                imageUrl={offer.image_url}
                title={offer.vehicle}
                subtitle={
                    offer.subtitle ? (
                        <SubtitleWrapper>
                            <SubtitleLine direction={direction}>{offer.subtitle}</SubtitleLine>
                        </SubtitleWrapper>
                    ) : (
                        <SubtitleWrapper>
                            {offer.free_cancellation ? (
                                <SubtitleLine direction={direction}>
                                    {t('carrental.free-cancellation')}
                                </SubtitleLine>
                            ) : (
                                <SubtitleLine direction={direction} />
                            )}
                            {offer.air_conditioning ? (
                                <SubtitleLine direction={direction}>
                                    {t('carrental.air-conditioning')}
                                </SubtitleLine>
                            ) : (
                                <SubtitleLine direction={direction} />
                            )}
                            {offer.manual ? (
                                <SubtitleLine direction={direction}>
                                    {t('carrental.automatic-gearbox')}
                                </SubtitleLine>
                            ) : (
                                <SubtitleLine direction={direction}>
                                    {t('carrental.manual-gearbox')}
                                </SubtitleLine>
                            )}
                        </SubtitleWrapper>
                    )
                }
                url={bookingUrl}
                primaryButton={
                    <CardLink href={`${bookingUrl}?btn=details`}>
                        {t('carrental.view-details')}
                    </CardLink>
                }
                paddingBottom={offer.id ? '1.25rem' : '0.75rem'}
                image={<CarRentalTemplate offer={offer} scale={0.328} />}
                onPress={() => {
                    const clickedOnCarRentalOfferEvent: ClickedOnCarRentalOfferEvent = {
                        type: Event.CLICKED_ON_CAR_RENTAL_OFFER,
                        pick_up_location: query?.PickUpLocation?.NameEn,
                        pick_up_time: query?.PickUpTime,
                        drop_off_location: query?.DropOffLocation?.NameEn,
                        drop_off_time: query?.DropOffTime,
                        total_price: offer.total_price,
                        currency: offer.currency,
                        vehicle: offer.vehicle,
                    };
                    sendEvent(clickedOnCarRentalOfferEvent);
                }}
            />
            {offer.id ? (
                <Likes
                    offerId={offer.id}
                    sendMessage={sendMessage}
                    offerType={MessageOfferType.CarRental}
                />
            ) : null}
        </>
    );
};
