import React from 'react';
import styled from 'styled-components';

export const WrapperContainer = styled.div({
    width: '800px',
    height: '421px',
    position: 'absolute',
});

interface IWrapperProps {
    imageUrl: string;
}
export const Wrapper = styled.div<IWrapperProps>((props) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    background: `url('${props.imageUrl}')`,
    backgroundSize: '100%',
    backgroundPosition: 'center',
    '&::before': {
        content: '""',
        position: 'absolute',
        height: 111,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(0deg, rgba(35, 31, 32, 0.91) 0%, rgba(0, 0, 0, 0) 98.92%)',
    },
    userSelect: 'none',
}));

export const PriceLabelContainer = styled.div<{ top: number }>((props) => ({
    position: 'absolute',
    minWidth: '206px',
    height: '121px',
    top: props.top,
    right: '0',
    background: props.theme.colors.priceTagBackground,
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
}));

export const PriceLabel = styled.div((props) => ({
    position: 'relative',
    width: '100%',
    height: '45px',
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '45px',
    lineHeight: '73px',
    display: 'flex',
    top: '18px',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    color: props.theme.colors.textLight,
}));

export const CurrencyLabel = styled.span((props) => ({
    position: 'relative',
    height: '45px',
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: '73px',
    display: 'flex',
    top: '5px',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    color: props.theme.colors.textLight,
    paddingRight: 5,
}));

export const DurationLabel = styled.div((props) => ({
    position: 'absolute',
    width: '100%',
    height: '27px',
    top: '75px',
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '27px',
    lineHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: props.theme.colors.textLight,
}));

export const AmenitiesWrapper = styled.div({
    position: 'absolute',
    bottom: '30px',
    right: '11px',
    display: 'block',
    '& > *': {
        display: 'inline-block',
        marginRight: '10px',
        width: '42px',
        height: '42px',
    },
});

export const RatingContainer = styled.div({
    position: 'absolute',
    width: '120px',
    height: '48px',
    bottom: '30px',
    left: '30px',
    border: '3px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'ltr',
});

export const RatingStar = styled.img((props) => ({
    width: '28px',
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '36px',
    display: 'flex',
    justifyContent: 'center',
    color: '#FFFFFF',
    marginLeft: '8px',
}));

export const RatingLabel = styled.div((props) => ({
    width: '82px',
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '36px',
    display: 'flex',
    justifyContent: 'center',
    color: '#FFFFFF',
}));

export const RatingDescriptionLabel = styled.div((props) => ({
    position: 'absolute',
    bottom: '40px',
    left: '162px',
    width: '300px',
    height: '29px',
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '29px',
    lineHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
    direction: 'ltr',
}));
