import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const NonSmoking = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.99996 0C6.41771 0 4.87099 0.469192 3.5554 1.34824C2.23981 2.22729 1.21443 3.47672 0.608924 4.93853C0.00342307 6.40034 -0.155004 8.00888 0.153678 9.56072C0.46236 11.1126 1.22429 12.538 2.34311 13.6569C3.46193 14.7757 4.88739 15.5376 6.43924 15.8463C7.99109 16.155 9.59962 15.9965 11.0614 15.391C12.5232 14.7855 13.7727 13.7602 14.6517 12.4446C15.5308 11.129 16 9.58225 16 8C15.9976 5.879 15.154 3.84555 13.6542 2.34578C12.1544 0.846002 10.121 0.00238211 7.99996 0V0ZM14.6666 8C14.668 9.53775 14.1352 11.0282 13.1594 12.2167L11.6094 10.6667H12.6666C12.8434 10.6667 13.013 10.5964 13.138 10.4714C13.2631 10.3464 13.3333 10.1768 13.3333 10V8.66667C13.3333 8.48986 13.2631 8.32029 13.138 8.19526C13.013 8.07024 12.8434 8 12.6666 8H8.94279L3.78329 2.84058C4.75961 2.04131 5.94282 1.53569 7.19525 1.38256C8.44768 1.22942 9.71786 1.43507 10.858 1.97557C11.9981 2.51606 12.9613 3.36919 13.6356 4.4357C14.3098 5.50221 14.6674 6.73824 14.6666 8V8ZM12 10V8.66667H12.6666V10H12ZM1.33329 8C1.33192 6.46225 1.86469 4.97177 2.84054 3.78333L7.05713 8H5.99996V10.6667H9.72379L12.2166 13.1594C11.2403 13.9587 10.0571 14.4643 8.80467 14.6174C7.55224 14.7706 6.28207 14.5649 5.14193 14.0244C4.0018 13.4839 3.03858 12.6308 2.36434 11.5643C1.69009 10.4978 1.33255 9.26176 1.33329 8V8Z"
                    fill="white"
                />
                <path
                    d="M10 5.33341H11.3333C11.5101 5.3336 11.6795 5.40391 11.8045 5.52889C11.9295 5.65387 11.9998 5.82332 12 6.00007V6.66674H13.3333V6.00007C13.3327 5.46982 13.1218 4.96146 12.7469 4.58652C12.3719 4.21157 11.8636 4.00067 11.3333 4.00007H10C9.82325 3.99987 9.65379 3.92957 9.52881 3.80459C9.40383 3.67961 9.33353 3.51016 9.33333 3.33341V2.66674H8V3.33341C8.0006 3.86366 8.2115 4.37202 8.58644 4.74696C8.96139 5.12191 9.46975 5.33281 10 5.33341V5.33341Z"
                    fill="white"
                />
                <path
                    d="M2.66659 8.66667V10C2.66659 10.1768 2.73683 10.3464 2.86185 10.4714C2.98687 10.5964 3.15644 10.6667 3.33325 10.6667H5.33325V8H3.33325C3.15644 8 2.98687 8.07024 2.86185 8.19526C2.73683 8.32029 2.66659 8.48985 2.66659 8.66667V8.66667Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
