import { captureException } from '@sentry/nextjs';
import axios, { CancelTokenSource } from 'axios';
import { position } from 'caret-pos';
import { Send } from 'components/icons/Send';
import { api } from 'lib/api';
import { Event, sendEvent } from 'lib/events';
import { useStore } from 'lib/store';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isIOS, osVersion } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { useChatInputState } from '../hooks/UseGlobalState';
import { useLocationAutocomplete } from '../hooks/useLocationAutocomplete';
import { ISendMessageOptions, TextDirection } from '../types';
import { getUserInitials } from '../utils';
import UserTags from './UserTags';

const TEXT_AREA_HEIGHT = 52;
const TAGGED_USER_ID_ATTRIBUTE = '__tagged_user_id__';

const TextArea = styled.span<{
    paddingLeft: number;
    paddingRight: number;
    direction?: TextDirection;
}>((props) => ({
    fontSize: 16,
    fontFamily: props.theme.fonts.default,
    margin: 0,
    paddingLeft: props.direction === 'rtl' ? 60 : props.paddingLeft,
    paddingRight: props.direction === 'rtl' ? props.paddingRight : 60,
    paddingBottom: 6,
    paddingTop: 0,
    border: 0,
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    outline: 0,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'inline-block',
    top: 12,
    left: 0,
    resize: 'none',
    overflow: 'auto',
    color: props.theme.colors.text,
    '&:focus': {
        outline: 'none',
    },
    maxHeight: 2 * TEXT_AREA_HEIGHT,
    ':contenteditable': {
        backgroundColor: 'green',
    },
    ':empty': {
        '::before': {
            color: props.theme.colors.darkgrey,
            content: `'${props.placeholder}'`,
        },
    },
    direction: props.direction || 'ltr',
}));

const TextAreaWrapper = styled.div({
    flex: '1 1 0',
    minWidth: '0',
    overflow: 'hidden',
});

const SendButtonWrapper = styled.div<{
    direction?: TextDirection;
}>((props) => ({
    position: 'absolute',
    display: 'inline-block',
    right: '0',
    left: props.direction === 'rtl' ? '0' : undefined,
    marginRight: 16,
    marginLeft: 16,
    marginTop: 14,
    width: '25px',
    height: '25px',
    ':hover': {
        cursor: 'pointer',
    },
}));

const TextRowWrapper = styled.div<{
    borderRadius: number;
    marginBottom: number;
    borderBottom?: string;
}>((props) => ({
    position: 'relative',
    background: props.theme.colors.background,
    border: `1px solid ${props.theme.colors.grey}`,
    borderBottomLeftRadius: props.borderRadius,
    borderBottomRightRadius: props.borderRadius,
    maxHeight: 108,
    minHeight: TEXT_AREA_HEIGHT,
    width: '100%',
    maxWidth: 640,
    display: 'flex',
    caretColor: props.theme.colors.primary,
    borderBottom: props.borderBottom,
    ':focus-within': {
        marginBottom: props.marginBottom,
    },
}));

// allows watching state of an uncontrolled input
const useInputState = (ref: HTMLElement | null, timeout = 200) => {
    const [input, setInput] = useState('');
    useEffect(() => {
        if (ref) {
            setInterval(() => setInput(ref.innerText), timeout);
        }
    }, [ref]);
    return input;
};

// Handles externally set input state by updating textarea's value to chat input.
// If should send is set to true it also sends the message.
const useExternalInputState = (
    textArea: HTMLElement | null,
    isGroup: boolean,
    handleSendMessage: (textArea: HTMLElement | null) => void
) => {
    const theme = useTheme();
    const input = useChatInputState((state) => state.input);
    const shouldSend = useChatInputState((state) => state.shouldSend);
    const setInput = useChatInputState((state) => state.setInput);
    const setShouldSend = useChatInputState((state) => state.setShouldSend);
    const botTag = useStore((store) => store.config.botTag);
    useEffect(() => {
        if (input && textArea) {
            clearValue(textArea);
            if (isGroup) {
                appendTag(textArea, botTag!, botTag!, true, theme.colors.primary);
            }
            textArea.appendChild(document.createTextNode(input));
            position(textArea, textArea.innerText.length);
            if (shouldSend) {
                handleSendMessage(textArea);
                setShouldSend(false);
            }
            setInput('');
            textArea.blur();
        }
    }, [input, isGroup, shouldSend]);
};

const createTagElement = (tag: string, id: string, tagColor: string) => {
    const tagElement = document.createElement('span');
    tagElement.setAttribute('contentEditable', 'true');
    tagElement.setAttribute(TAGGED_USER_ID_ATTRIBUTE, id);
    tagElement.setAttribute('style', `color: ${tagColor}`);
    tagElement.appendChild(document.createTextNode(`@${tag}`));
    return tagElement;
};

const appendTag = (
    textArea: HTMLSpanElement | null,
    tag: string,
    id: string,
    prepend = false,
    tagColor: string // eslint-disable-next-line max-params
) => {
    if (!tag) {
        return;
    }
    if (textArea) {
        const tagElement = createTagElement(tag, id, tagColor);
        if (prepend) {
            textArea.prepend(tagElement);
        } else {
            if (!textArea.innerText.endsWith(' ')) {
                textArea.appendChild(document.createTextNode(' '));
            }
            textArea.appendChild(tagElement);
        }
        textArea.appendChild(document.createTextNode(' '));
        textArea.focus();
        position(textArea, textArea.innerText.length);
    }
};

const clearValue = (textArea: HTMLSpanElement) => {
    // clearing textarea value by removing all child nodes
    while (textArea.firstChild) {
        textArea.removeChild(textArea.firstChild);
    }
};

const removeBotTag = (botTag: string, textArea: HTMLSpanElement | null) => {
    if (!textArea) {
        return;
    }
    textArea.childNodes.forEach((childNode) => {
        if (
            childNode.nodeType === Node.ELEMENT_NODE &&
            (childNode as HTMLElement).getAttribute(TAGGED_USER_ID_ATTRIBUTE) === botTag
        ) {
            textArea.removeChild(childNode);
        }
    });
};

export const TextRow = ({
    sendMessage,
    autocompleteActive,
}: {
    sendMessage: (text: string, options?: ISendMessageOptions) => void;
    autocompleteActive: boolean;
}) => {
    const theme = useTheme();
    const { t } = useTranslation('chat');
    const textArea = useRef<HTMLElement>(null);
    const inputState = useInputState(textArea.current);
    const { setLocations } = useLocationAutocomplete();

    const router = useRouter();
    const isGroup = router.pathname.includes('groups/[id]');
    const groupID = router.query['id'];
    const botTag = useStore((store) => store.config.botTag);

    const languageCode = useStore((store) => store.languageCode);
    const textDirection = useStore((store) => store.textDirection);

    useEffect(() => {
        if (!groupID) {
            return;
        }
        let appendEddy = true;
        if (textArea.current) {
            for (let childNode of textArea.current.childNodes) {
                if (
                    childNode.nodeType === Node.ELEMENT_NODE &&
                    (childNode as HTMLElement).hasAttribute(TAGGED_USER_ID_ATTRIBUTE)
                ) {
                    appendEddy = false;
                    break;
                }
            }
        }
        appendEddy && appendTag(textArea.current, botTag!, botTag!, true, theme.colors.primary);
    }, [groupID]);

    const autoCompleteCancelToken = useRef<CancelTokenSource | null>(null);

    const handleSendMessage = useCallback(
        (textArea: HTMLSpanElement | null) => {
            if (!textArea) {
                return;
            }
            let message = '';
            const taggedUsers: string[] = [];
            let disableAssistant = isGroup;
            textArea.childNodes.forEach((node) => {
                if (node.nodeType === Node.ELEMENT_NODE) {
                    const userID = (node as HTMLElement).getAttribute(TAGGED_USER_ID_ATTRIBUTE);
                    if (userID) {
                        if (userID === botTag) {
                            disableAssistant = false;
                        }
                        taggedUsers.push(`web:${userID}`);
                        message += `<${node.textContent}>`;
                    }
                } else {
                    message += node.textContent;
                }
            });
            sendMessage(message, {
                tagged_users: taggedUsers,
                assistant_disabled: disableAssistant,
            });
            clearValue(textArea);
            setLocations([]);
            autoCompleteCancelToken.current?.cancel();
            if (message.toLowerCase().includes(`<@${botTag}>`.toLowerCase())) {
                appendTag(textArea, botTag!, botTag!, true, theme.colors.primary);
            }
            textArea.focus();
        },
        [textArea.current, sendMessage]
    );

    const handleAutoComplete = useCallback(
        (textArea: HTMLSpanElement | null) => {
            if (!textArea) {
                return;
            }
            let message = '';
            textArea.childNodes.forEach((node) => {
                if (node.nodeType !== Node.ELEMENT_NODE) {
                    message += node.textContent;
                }
            });
            if (message.length > 2) {
                autoCompleteCancelToken.current?.cancel();
                autoCompleteCancelToken.current = axios.CancelToken.source();
                api.getLocationHints(message, autoCompleteCancelToken.current.token, languageCode)
                    .then((res) => {
                        setLocations(res.data.locations);
                    })
                    .catch((err) => {
                        if (!axios.isCancel(err)) {
                            captureException(err);
                        }
                    });
            } else {
                setLocations([]);
            }
            textArea.focus();
        },
        [textArea.current, languageCode]
    );

    let chrome = false;
    let firefox = false;
    if (typeof window !== 'undefined') {
        const userAgent = window?.navigator.userAgent.toLowerCase();
        chrome = /crios/.test(userAgent);
        firefox = /fxios/.test(userAgent);
    }
    const isSafariIOS15 = isIOS && !chrome && !firefox && osVersion.startsWith('15');

    useExternalInputState(textArea.current, isGroup, handleSendMessage);
    useEffect(() => {
        if (textArea.current && !isSafariIOS15) {
            textArea.current.focus();
        }
    }, []);
    const isWidget = useStore((store) => store.isWidget);

    return (
        <TextRowWrapper
            marginBottom={isSafariIOS15 ? 50 : 0}
            borderRadius={isWidget ? 0 : 5}
            borderBottom={isWidget ? 'none' : undefined}
            onClick={() => textArea.current?.focus()}
        >
            {isGroup ? (
                <UserTags
                    onUserClick={(user) => {
                        removeBotTag(botTag!, textArea.current);
                        appendTag(
                            textArea.current,
                            user.id === botTag ? botTag : getUserInitials(user) || user.id,
                            user.id,
                            false,
                            theme.colors.primary
                        );
                    }}
                />
            ) : null}
            <TextAreaWrapper>
                <TextArea
                    direction={textDirection}
                    contentEditable={true}
                    ref={textArea}
                    paddingLeft={isGroup ? 8 : 16}
                    paddingRight={isGroup ? 8 : 16}
                    onKeyDown={(event) => {
                        if (event.keyCode === 13 && !event.shiftKey && textArea.current) {
                            event.preventDefault();
                            handleSendMessage(textArea.current);
                            sendEvent({ type: Event.SEND_BUTTON_CLICKED });
                        } else if (event.keyCode === 8 || event.keyCode === 46) {
                            const selection = document.getSelection();
                            const tagNode = selection?.anchorNode?.parentElement;
                            if (tagNode?.hasAttribute(TAGGED_USER_ID_ATTRIBUTE)) {
                                tagNode.remove();
                            }
                        }
                    }}
                    onInput={(event) => {
                        const val = (event.target as HTMLElement).innerText;
                        if (textArea.current) {
                            if (val === '\n') {
                                textArea.current.focus();
                                clearValue(textArea.current);
                            }

                            if (autocompleteActive) {
                                handleAutoComplete(textArea.current);
                            }

                            const selection = document.getSelection();
                            const tagNode = selection?.anchorNode?.parentElement;
                            if (tagNode?.hasAttribute(TAGGED_USER_ID_ATTRIBUTE)) {
                                // editing a tag, replace with text node
                                const tagPosition = position(tagNode);
                                tagNode.replaceWith(tagNode.innerText);
                                const curPosition = position(textArea.current);
                                position(textArea.current, curPosition.pos + tagPosition.pos);
                            } else if (tagNode?.tagName.toLowerCase() === 'font') {
                                // replacing a styled span with a textnode creates a font tag with the same styling
                                // this seems like a webkit issue which I couldn't reproduce on firefox
                                // replacing with a text node instead
                                tagNode.replaceWith(val);
                                const curPosition = position(textArea.current);
                                position(textArea.current, curPosition.pos + 1);
                            }
                        }
                    }}
                    onBlur={(event) => {
                        const val = (event.target as HTMLElement).innerText;
                        event.preventDefault();
                        if (val === '\n' && textArea.current) {
                            textArea.current.focus();
                            clearValue(textArea.current);
                        }
                    }}
                    onPaste={(event) => {
                        const paste = event.clipboardData.getData('text');
                        const selection = window.getSelection();
                        if (!selection || !selection.rangeCount) {
                            return;
                        }
                        selection.deleteFromDocument();
                        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
                        selection.collapseToEnd();
                        event.preventDefault();
                    }}
                    placeholder={t('message-input-placeholder')}
                />
            </TextAreaWrapper>
            <SendButtonWrapper
                direction={textDirection}
                // mobile safari fix ios15
                onTouchEnd={(e: any) => {
                    e.preventDefault();
                    handleSendMessage(textArea.current);
                    sendEvent({ type: Event.SEND_BUTTON_CLICKED });
                }}
                onClick={(e) => {
                    e.preventDefault();
                    handleSendMessage(textArea.current);
                    sendEvent({ type: Event.SEND_BUTTON_CLICKED });
                }}
            >
                <Send color={inputState !== '' ? theme.colors.primary : theme.colors.disabled} />
            </SendButtonWrapper>
        </TextRowWrapper>
    );
};

export default TextRow;
