export const ArrowRight = ({ color = '#000000' }: { color?: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path
            d="M2.58746 10.2693H17.5471"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.7134 4.43604L17.5468 10.2694L11.7134 16.1027"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
