import { RoundedLink } from 'components/molecules/link';
import { Scroller } from 'components/organisms/Scroller';
import { ClickedQuickReplyEvent, Event, sendEvent } from 'lib/events';
import React, { useEffect } from 'react';
import styledWeb from 'styled-components';
import styled from 'styled-components/native';

import { useChatInputState, useScrollToBottomState } from '../hooks/UseGlobalState';
import { Hint, TextDirection } from '../types';

export const QuickReplyContainer = styled.View({
    padding: '2px 8px 2px 8px',
    display: 'flex',
    flexDirection: 'row',
});

export const QuickReply = styledWeb(RoundedLink)<{ direction?: TextDirection }>((props) => ({
    '&:nth-child(n+2)': {
        marginLeft: '12px',
    },
    direction: props.direction || 'ltr',
}));

const NavigationStep = 200;

interface IProps {
    hints: Array<Hint>;
    direction?: TextDirection;
}

export default function QuickReplies({ hints, direction }: IProps) {
    const setInput = useChatInputState((state) => state.setInput);
    const setScrollToBottom = useScrollToBottomState((state) => state.setScrollToBottom);
    useEffect(() => {
        setScrollToBottom(true);
    }, []);
    return (
        <Scroller step={NavigationStep} centered={true}>
            <QuickReplyContainer>
                {hints.map((hint, index) => (
                    <QuickReply
                        onClick={() => {
                            const chatEvent: ClickedQuickReplyEvent = {
                                type: Event.CLICKED_QUICK_REPLY,
                                label: hint.label,
                                tracking_id: hint.tracking_id,
                            };
                            sendEvent(chatEvent);
                            setInput(hint.label, true);
                        }}
                        direction={direction}
                        key={`quickreply-${index}`}
                    >
                        {hint.label}
                    </QuickReply>
                ))}
            </QuickReplyContainer>
        </Scroller>
    );
}
