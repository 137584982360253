import UserAvatar from 'components/atoms/UserAvatar';
import Avatar from 'components/common/Avatar';
import { GroupUser } from 'lib/types';
import React, { memo } from 'react';
import styled from 'styled-components/native';

import { ConversationItem, Expectation, IBubble } from '../types';
import Renderer from '../ui/Renderer';
import { getMessageUserId, getUserInitials, sentByCurrentUser } from '../utils';
import { CalendarCarousel } from './Calendar';
import QuickReplies from './QuickReplies';

const AvatarWrapper = styled.View({
    paddingRight: '8px',
    alignSelf: 'flex-end',
});

const AvatarSpacer = styled.View({
    height: 28,
    width: 27,
});

const MessageNodesWrapper = styled.View({
    width: '100%',
});

const AdditionalMessageElement = styled.View({
    paddingBottom: 16,
});

const NodeWrapper = styled.View((props: { message: ConversationItem; isOtherUser: boolean }) => {
    const baseStyle = {
        flexDirection: 'row' as const,
        display: 'flex' as const,
        paddingLeft: '16px',
        paddingRight: '16px',
    };

    const userMessage =
        !props.message.bot && !props.isOtherUser
            ? {
                  justifyContent: 'flex-end',
              }
            : {};

    const lastMessageInGroup =
        props.message.isLastMessageInGroup && !props.message.hasAvatar
            ? { marginBottom: '0px' }
            : { marginBottom: '8px' };

    const isCarousel = props.message.isCarousel
        ? { paddingLeft: '0px', paddingRight: '0px', marginBottom: '8px' }
        : {};

    return { ...baseStyle, ...lastMessageInGroup, ...isCarousel, ...userMessage };
});

const ChatBubbleWrapper = styled.View((props: { message: ConversationItem }) => {
    const baseBubble = {};

    // Spec bubble size 85%, (-27 handling avatar size)
    // left and right padding of 16px already included in the element window
    const regularBubble = !props.message.isCarousel
        ? {
              maxWidth: 'calc(85% - 27px)',
          }
        : {
              width: '100%',
          };

    return { ...baseBubble, ...regularBubble };
});

export const ChatMessage = memo((props: IBubble) => {
    let message = props.message;
    const nextMessage = props.nextMessage;
    const showAvatar =
        !nextMessage || nextMessage.user_id !== message.user_id || nextMessage.isCarousel;
    const isBot = !!message.bot;
    const isOtherUser = !isBot && !sentByCurrentUser(message, props.user);
    const senderUserId = getMessageUserId(message);
    const showCalendar = props.isLastMessage && message.bot?.expectation === Expectation.Date;
    const direction = props.direction;

    return (
        <>
            <MessageNodesWrapper>
                <NodeWrapper message={message} isOtherUser={isOtherUser}>
                    {isBot && showAvatar && !message.isCarousel ? (
                        <AvatarWrapper>
                            <Avatar width={27} />
                        </AvatarWrapper>
                    ) : null}
                    {!showAvatar && !message.isCarousel ? (
                        <AvatarWrapper>
                            <AvatarSpacer />
                        </AvatarWrapper>
                    ) : null}
                    {isOtherUser && showAvatar ? (
                        <AvatarWrapper>
                            <UserAvatar
                                index={(props.groupUsers as GroupUser[]).findIndex(
                                    (user) => user.id === senderUserId
                                )}
                            >
                                {getUserInitials(
                                    props.groupUsers?.find((user) => user.id === senderUserId)
                                )}
                            </UserAvatar>
                        </AvatarWrapper>
                    ) : null}
                    <ChatBubbleWrapper message={message}>
                        <Renderer
                            message={message}
                            index={0}
                            sendMessage={props.sendMessage}
                            groupUsers={props.groupUsers as GroupUser[]}
                            direction={direction}
                        />
                    </ChatBubbleWrapper>
                </NodeWrapper>
            </MessageNodesWrapper>
            {props.isLastMessage && message.bot?.hints ? (
                <AdditionalMessageElement>
                    <QuickReplies hints={message.bot?.hints} direction={direction} />
                </AdditionalMessageElement>
            ) : undefined}
            {showCalendar ? (
                <AdditionalMessageElement>
                    <CalendarCarousel />
                </AdditionalMessageElement>
            ) : null}
        </>
    );
});
