import styled from 'styled-components/native';

import { Link } from '../../atoms/link';

export const CardLink = styled(Link)((props) => ({
    width: '100%',
    height: '2.5rem',
    display: 'block',
    textAlign: 'center',
    paddingTop: '0.7rem',
    fontSize: 14,
    fontWeight: 500,
    userSelect: 'none',
    color: props.buttonColor === 'primary' ? props.theme.colors.text : props.theme.colors.textLight,
    border: `1px solid ${props.buttonColor === 'primary' ? '#D1DCE5' : props.theme.colors.primary}`,
    backgroundColor:
        props.buttonColor === 'primary' ? props.theme.colors.white : props.theme.colors.primary,
    borderRadius: 4,
    textDecoration: 'none',
    lineHeight: '1rem',
}));
