import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import styled from 'styled-components';

import { AccommodationOffer, TextDirection } from '../chat/types';
import { VanillaAccommodationTemplate } from './accommodation/vanilla';
import * as amenityIconComponents from './icons/amenities';

function getRatingDescription(rating: number) {
    let description = '';
    switch (true) {
        case rating >= 10:
            description = 'Perfect';
            break;
        case rating >= 9.5:
            description = 'Exceptional';
            break;
        case rating >= 9:
            description = 'Superb';
            break;
        case rating >= 8.6:
            description = 'Fabulous';
            break;
        case rating >= 8:
            description = 'Very good';
            break;
        case rating >= 7:
            description = 'Good';
            break;
        case rating >= 6:
            description = 'Pleasant';
            break;
    }
    return description;
}

enum amenityTypeEnum {
    Breakfast = 1,
    AirportShuttle,
    FreeParking,
    InternetAccess,
    SwimmingPool,
    FitnessRoom,
    NonSmoking,
    BusinessCenter,
    Restaurant,
    Bar,
    PetsAllowed,
    FacilitiesForDisabled,
    Disinfected,
    AllInclusive,
    Beachfront,
}

function functionMapAmenitiesToIcon(amenities: string[]): Array<(key: number) => JSX.Element> {
    const topAmenitiesIcons = amenities
        .map((a) => parseInt(a, 10))
        .sort((a, b) => a - b)
        .slice(0, 3)
        .map((a) => {
            switch (a) {
                case amenityTypeEnum.Breakfast:
                    return amenityIconComponents.Breakfast;
                case amenityTypeEnum.AirportShuttle:
                    return amenityIconComponents.AirportShuttle;
                case amenityTypeEnum.FreeParking:
                    return amenityIconComponents.FreeParking;
                case amenityTypeEnum.InternetAccess:
                    return amenityIconComponents.InternetAccess;
                case amenityTypeEnum.SwimmingPool:
                    return amenityIconComponents.SwimmingPool;
                case amenityTypeEnum.FitnessRoom:
                    return amenityIconComponents.FitnessRoom;
                case amenityTypeEnum.NonSmoking:
                    return amenityIconComponents.NonSmoking;
                case amenityTypeEnum.BusinessCenter:
                    return amenityIconComponents.BusinessCenter;
                case amenityTypeEnum.Restaurant:
                    return amenityIconComponents.Restaurant;
                case amenityTypeEnum.Bar:
                    return amenityIconComponents.Bar;
                case amenityTypeEnum.PetsAllowed:
                    return amenityIconComponents.PetsAllowed;
                case amenityTypeEnum.FacilitiesForDisabled:
                    return amenityIconComponents.FacilitiesForDisabled;
                default:
                    return () => null;
            }
        });
    return topAmenitiesIcons as Array<() => JSX.Element>;
}

export const ScaledImage = styled.div<{ scale: number }>((props) => ({
    transform: `scale(${props.scale || 1})`,
    position: 'absolute',
}));

export const AccommodationTemplate = (props: {
    offer: AccommodationOffer;
    scale: number;
    direction: TextDirection;
}) => {
    const offer = props.offer;
    let disinfected = false;
    if (offer.amenities?.find((v) => parseInt(v, 10) === amenityTypeEnum.Disinfected)) {
        disinfected = true;
    }

    return (
        <ScaledImage scale={props.scale}>
            {VanillaAccommodationTemplate({
                price: offer.total_price,
                rating: offer.rating,
                ratingDescription: offer.rating_label || getRatingDescription(offer.rating),
                amenityComponents: functionMapAmenitiesToIcon(
                    offer.amenities ? offer.amenities : []
                ),
                disinfected,
                imageUrl: offer.images?.length > 0 ? offer.images[0] : '',
                durationNights: offer.duration_days,
                currencySymbol: getSymbolFromCurrency(offer.currency),
                deal: offer.deal,
                durationLabel: offer.duration_label,
                direction: props.direction,
            })}
        </ScaledImage>
    );
};
