import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const BusinessCenter = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.6067 8.60344C12.5852 9.36953 11.3382 9.90141 9.98097 10.1638C9.84391 11.1346 9.00803 11.8839 8 11.8839C6.99197 11.8839 6.15609 11.1346 6.01903 10.1638C4.66184 9.90141 3.41484 9.36953 2.39334 8.60344C0.849969 7.44591 0 5.89428 0 4.23438V12.75C0 14.023 1.03566 15.0586 2.30859 15.0586H13.6914C14.9643 15.0586 16 14.023 16 12.75V4.23438C16 5.89428 15.15 7.44591 13.6067 8.60344Z"
                    fill="white"
                />
                <path
                    d="M5.99891 9.20197V8.3505C5.99891 8.09163 6.20878 7.88175 6.46766 7.88175H9.53234C9.79122 7.88175 10.0011 8.09163 10.0011 8.3505V9.20197C12.9238 8.56756 15.0625 6.58087 15.0625 4.23438V3.76562H11.293V2.83413C11.293 1.79047 10.4439 0.941406 9.40025 0.941406H6.59978C5.55616 0.941406 4.70706 1.79047 4.70706 2.83413V3.76562H0.9375V4.23438C0.9375 6.58087 3.07619 8.56756 5.99891 9.20197V9.20197ZM5.64453 2.83413C5.64453 2.30741 6.07306 1.87891 6.59975 1.87891H9.40022C9.92691 1.87891 10.3554 2.30741 10.3554 2.83413V3.76562H5.6445V2.83413H5.64453Z"
                    fill="white"
                />
                <path
                    d="M9.06368 8.81915H6.93649V9.88275C6.93649 10.4692 7.41365 10.9463 8.00009 10.9463C8.58652 10.9463 9.06368 10.4692 9.06368 9.88275V8.81915Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
