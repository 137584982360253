import TagManager from 'react-gtm-module';

import { ConversationItem } from '../components/chat/types';

export const GTM_EVENTS = {
    OPENED_REGISTRATION: 'Opened Web Chat Registration Page',
    SUBMITTED_EMAIL: 'Submitted Email for Web Chat Registration',
    SEARCHED_FOR_FLIGHTS: 'Searched for Flights',
    SEARCHED_FOR_ACCOMMODATION: 'Looked for Accommodation',
    RECEIVED_FLIGHT_OFFERS: 'Received Flight Offers',
    RECEIVED_ACCOMMODATION_OFFERS: 'Received Accommodation Offers',
    USER_SIGN_UP: 'User Signed Up',
    SEND_MESSAGE: 'Send Message',
    SESSION_INITIATION: 'Session Initiation',
    CLICKED_ON_ACCOMMODATION: 'Clicked on Accommodation Booking',
    CLICKED_ON_FLIGHT: 'Clicked on Flight Booking',
    FLIGHT_SHARE: 'Clicked on Flight Sharing',
    CONTINUED_FLIGHT_SHARE: 'Continued Flight Sharing',
    ACCOMMODATION_SHARE: 'Clicked on Accommodation Sharing',
    CONTINUED_ACCOMMODATION_SHARE: 'Continued Accommodation Sharing',
    LIKED_OFFER: 'Liked Offer',
    UNLIKED_OFFER: 'Unliked Offer',
    PARTNER_REGISTERED: 'Partner Registered',
};

const MARKER_REGEX = {
    FLIGHT_SEARCH: '^🔎 Searching...\\n✈️',
    ACCOMMODATION_SEARCH: '^🔎 Searching...\\n🏨',
};

export const handleGTMEvents = (message: ConversationItem) => {
    if (message?.bot) {
        if (message.bot.text?.match(MARKER_REGEX.FLIGHT_SEARCH)) {
            TagManager.dataLayer({
                dataLayer: { event: GTM_EVENTS.SEARCHED_FOR_FLIGHTS },
            });
        } else if (message.bot.text?.match(MARKER_REGEX.ACCOMMODATION_SEARCH)) {
            TagManager.dataLayer({
                dataLayer: { event: GTM_EVENTS.SEARCHED_FOR_ACCOMMODATION },
            });
        } else if (message.bot.flight_search_result) {
            TagManager.dataLayer({
                dataLayer: { event: GTM_EVENTS.RECEIVED_FLIGHT_OFFERS },
            });
        } else if (message.bot.accommodation_search_result) {
            TagManager.dataLayer({
                dataLayer: { event: GTM_EVENTS.RECEIVED_ACCOMMODATION_OFFERS },
            });
        }
    } else if (message?.user) {
        TagManager.dataLayer({
            dataLayer: { event: GTM_EVENTS.SEND_MESSAGE },
        });
    }
};
