import { DateTime } from 'luxon';

import { ConversationItem } from '../components/chat/types';

export interface User {
    email?: string;
    created_at?: string;
    is_new?: boolean;
    session_token?: string;
    guest?: boolean;
    id: string;
    assistant_disabled: boolean;
    language_code?: string;
}

export interface ConversationMessages {
    handle: string;
    messages: ConversationItem[];
    offset: number;
    limit: number;
}

export interface ConversationLikes {
    messages: any[];
}

export interface IOffersRequest {
    searchId: string;
    offset: number;
    limit: number;
}

export enum GroupUserRole {
    'Member',
    'Admin',
}

export enum GroupUserStatus {
    'Active',
    'Removed',
}
export interface GroupUser {
    id: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    role: GroupUserRole;
    status: GroupUserStatus;
}

interface Member {
    id: string;
    created_at: string;
    status: string;
    role: string;
}
export interface Group {
    id: string;
    name: string;
    photo: string;
    created_at: string;
    share_token: string;
    admin_user: string;
    users: Array<string>;
    members: Array<Member>;
    flight: IGroupFlight;
    last_message_at: string;
    last_message_read_at: string;
}

export interface IGroupFlight {
    from: string;
    to: string;
}

export interface PartnerReport {
    id: string;
    name: string;
    type: number;
    created_at: string;
}

export enum PartnerSalesType {
    Booking = 'booking',
    Kiwi = 'kiwi',
    GetYourGuide = 'get_your_guide',
    PricelineCars = 'priceline_cars',
}

export interface PartnerSale<T = BookingData | KiwiData | GetYourGuideData | PricelineCarsData> {
    id: string;
    external_id: string;
    partner_id: string;
    type: PartnerSalesType;
    data: T;
    updated_at: string;
}

export interface BookingData {
    booking_date: string;
    check_in_date: string;
    check_out_date: string;
    status: string;
    your_commission: number;
    ttv: number;
    property_name: string;
}

export interface KiwiData {
    booking_timestamp: string;
    price: number;
    o_d: string;
    departure_time: string;
    return_departure_time: string;
    status: string;
    total_commission: number;
}

export interface GetYourGuideData {
    status: boolean;
    activity_name: string;
    location: string;
    booking_date: string;
    activity_date: string;
    commission: number;
    total: number;
}

export interface PricelineCarsData {
    date_time_of_booking: string;
    total_price: number;
    est_commission: number;
    car_company_name: string;
    car_type: string;
    number_of_days: number;
    pickup_location_city: string;
    status: string;
}

export interface PartnerPlatformConfig {
    messenger: Array<{
        page_id: string;
        page_name: string;
    }>;
    instagram: Array<{
        page_id: string;
        page_name: string;
        profile_id: string;
    }>;
}

export interface Integrations {
    [key: string]: boolean;
    accommodation: boolean;
    flights: boolean;
    activities: boolean;
    cars: boolean;
    places: boolean;
    visa: boolean;
    insurance: boolean;
    baggageProtection: boolean;
    airportShuttle: boolean;
}

export interface PartnerConfig {
    [key: string]: any;
    botTag?: string;
    botName?: string;
    preview?: {
        title?: string;
        imgUrl?: string;
        deeplink?: string;
    };
    icons?: {
        logoUrl?: string;
        botIconUrl?: string;
        headerIconUrl?: string;
    };
    colors?: {
        primary?: string;
        primaryLight?: string;
        primaryActive?: string;
        flightCardBackground?: string;
        priceTagBackground?: string;
        text?: string;
        textLight?: string;
        icon?: string;
    };
    fonts?: {
        default?: string;
        defaultUrl?: string;
        bold?: string;
        boldUrl?: string;
    };
    borderRadius?: {
        quickReplies?: string;
        likes?: string;
        cards?: string;
        userBubble?: string;
        botBubble?: string;
        widget?: string;
    };
    privacyPolicyUrl?: string;
    hidePoweredBy?: boolean;
    // deprecated, kept for backwards compatibility
    primaryColor?: string;
    activePrimaryColor?: string;
    lightPrimaryColor?: string;
    logoUrl?: string;
    botIconUrl?: string;
    previewImgUrl?: string;
    previewTitle?: string;
    deeplink?: string;
    hideCloseButton?: boolean;
    defaultLanguageCode?: string;
    defaultCurrency?: string;
    previewDelaySeconds?: number;
    showDelaySeconds?: number;
    locationKeyword?: string;
    integrations?: Integrations;
}

export interface Currency {
    id: string;
    name: string;
    symbol: string;
}

export interface PartnerProfile {
    firstName?: string;
    lastName?: string;
    phone?: string;
    VAT?: string;
    companyCode?: string;
    companyName?: string;
    country?: string;
    city?: string;
    streetAddress?: string;
    postCode?: string;
    payeeName?: string;
    bankName?: string;
    IBAN?: string;
    swift?: string;
}

export enum PartnerPlanType {
    Basic = 'basic',
    Premium = 'premium',
    Enterprise = 'enterprise',
}

export interface Partner {
    id: string;
    email: string;
    config: PartnerConfig;
    profile: PartnerProfile;
    plan: PartnerPlanType;
    plan_expiration_date: string;
    plan_renew_enabled: boolean;
}

export const getActivePlan = (partner: Partner) => {
    if (
        !partner.plan ||
        !partner.plan_expiration_date ||
        DateTime.fromISO(partner.plan_expiration_date) < DateTime.local()
    ) {
        return PartnerPlanType.Basic;
    }
    return partner.plan;
};
