import create, { State } from 'zustand';

import { Location } from '../types';

interface IAutocompleteState extends State {
    locations: Location[];
    setLocations: (locations: Location[]) => void;
}

export const useLocationAutocomplete = create<IAutocompleteState>(
    (set): IAutocompleteState => ({
        locations: [],
        setLocations: (locations: Location[]) => {
            set({ locations });
        },
    })
);
