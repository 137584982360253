import {
    AmenitiesWrapper,
    CurrencyLabel,
    DurationLabel,
    PriceLabel,
    PriceLabelContainer,
    RatingContainer,
    RatingDescriptionLabel,
    RatingLabel,
    RatingStar,
    Wrapper,
    WrapperContainer,
} from 'components/templates/common';
import React from 'react';

interface IProps {
    price: number;
    imageUrl: string;
    currencySymbol: string;
    durationDays: number;
    amenityComponents: Array<(key: number) => JSX.Element>;
    rating?: string;
    ratingAmount?: number;
}

export const VanillaCarRentalsTemplate = (props: IProps) => {
    return (
        <WrapperContainer>
            <Wrapper imageUrl={props.imageUrl}>
                <PriceLabelContainer top={33}>
                    <PriceLabel>
                        <CurrencyLabel>{props.currencySymbol}</CurrencyLabel>
                        {Intl.NumberFormat('en-US').format(props.price)}
                    </PriceLabel>
                    <DurationLabel>
                        for {props.durationDays} {props.durationDays === 1 ? 'day' : 'days'}
                    </DurationLabel>
                </PriceLabelContainer>
                {props.rating ? (
                    <RatingContainer>
                        <RatingStar src="/images/star.svg" />
                        <RatingLabel>{props.rating}</RatingLabel>
                    </RatingContainer>
                ) : null}
                {props.ratingAmount ? (
                    <RatingDescriptionLabel>{props.ratingAmount}+ Reviews</RatingDescriptionLabel>
                ) : null}
                <AmenitiesWrapper>{props.amenityComponents.map((a, i) => a(i))}</AmenitiesWrapper>
            </Wrapper>
        </WrapperContainer>
    );
};
