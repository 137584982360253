import { CardLink } from 'components/molecules/link';
import { Card } from 'components/organisms/card';
import { ActivitiesTemplate } from 'components/templates';
import { ClickedOnActivityOfferEvent, Event, sendEvent } from 'lib/events';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
    ActivityOffer,
    ActivitySearchQuery,
    ISendMessageOptions,
    MessageOfferType,
    TextDirection,
} from '../types';
import { Likes } from './Likes';

interface IProps {
    offer: ActivityOffer;
    query?: ActivitySearchQuery;
    sendMessage: (text: string, options: ISendMessageOptions) => void;
    direction: TextDirection;
}

const ActivityTitle = styled.div<{ direction?: TextDirection }>((props) => ({
    lineHeight: '17px',
    height: '44px',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: props.theme.fonts.default,
    color: props.theme.colors.text,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    display: '-webkit-box',
    ' -webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    direction: props.direction || 'ltr',
}));

const SubtitleLine = styled.div<{ direction?: TextDirection }>((props) => ({
    fontFamily: props.theme.fonts.default,
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '14px',
    color: props.theme.colors.darkgrey,
    paddingBottom: 4,
    direction: props.direction || 'ltr',
}));

const SubtitleWrapper = styled.div({
    paddingTop: 4,
    paddingBottom: 4,
    height: 44,
    whiteSpace: 'pre-wrap',
});

const handleClickedOnActivityOffer = (offer: ActivityOffer, query?: ActivitySearchQuery) => {
    const clickedOnActivityOfferEvent: ClickedOnActivityOfferEvent = {
        type: Event.CLICKED_ON_ACTIVITY_OFFER,
        location: query?.Location?.NameEn,
        title: offer.title,
        subtitle: offer.subtitle,
        rating: offer.rating,
        price: offer.price,
        duration: offer.duration,
        currency: offer.currency,
    };
    sendEvent(clickedOnActivityOfferEvent);
};

export const ActivityCard = React.memo(({ offer, query, sendMessage, direction }: IProps) => {
    const { t } = useTranslation('cards');
    return (
        <>
            <Card
                key={offer.id}
                imageUrl={(offer.images || [])[0]}
                image={<ActivitiesTemplate offer={offer} scale={0.328} direction={direction} />}
                title={<ActivityTitle direction={direction}>{offer.title}</ActivityTitle>}
                subtitle={
                    offer.subtitle ? (
                        <SubtitleWrapper>
                            <SubtitleLine direction={direction}>{offer.subtitle}</SubtitleLine>
                        </SubtitleWrapper>
                    ) : (
                        <SubtitleWrapper>
                            {offer.best_seller ? (
                                <SubtitleLine direction={direction}>
                                    {t('activity.best-seller')}
                                </SubtitleLine>
                            ) : null}
                            {offer.duration ? (
                                <SubtitleLine direction={direction}>
                                    {t('activity.duration')}: {offer.duration}
                                </SubtitleLine>
                            ) : null}
                        </SubtitleWrapper>
                    )
                }
                url={offer.booking_url}
                primaryButton={
                    <CardLink href={offer.booking_url}>{t('activity.view-details')}</CardLink>
                }
                onPress={() => handleClickedOnActivityOffer(offer, query)}
                paddingBottom={offer.id ? '1.25rem' : '0.75rem'}
            />
            {offer.id ? (
                <Likes
                    offerId={offer.id}
                    sendMessage={sendMessage}
                    offerType={MessageOfferType.Activity}
                />
            ) : null}
        </>
    );
});
