import { TextDirection } from 'components/chat/types';
import {
    CurrencyLabel,
    DurationLabel,
    PriceLabel,
    PriceLabelContainer,
    RatingContainer,
    RatingDescriptionLabel,
    RatingLabel,
    RatingStar,
    Wrapper,
    WrapperContainer,
} from 'components/templates/common';
import React from 'react';

interface IProps {
    price: number;
    priceDescription: string;
    imageUrl: string;
    currencySymbol: string;
    rating?: number;
    ratingAmount?: number;
    priceLabel?: string;
    ratingLabel?: string;
    direction: TextDirection;
}

export const VanillaActivitiesTemplate = (props: IProps) => {
    const isRtl = props.direction === 'rtl';
    const price = Intl.NumberFormat('en-US').format(props.price);
    const currencySymbol = <CurrencyLabel>{props.currencySymbol}</CurrencyLabel>;
    let priceLabel = '';
    if (props.priceDescription === 'individual') {
        priceLabel = 'per person';
    } else if (props.priceDescription === 'group') {
        priceLabel = 'per group';
    }
    priceLabel = props.priceLabel || priceLabel;
    return (
        <WrapperContainer>
            <Wrapper imageUrl={props.imageUrl}>
                <PriceLabelContainer top={33}>
                    <PriceLabel>
                        {isRtl ? [price, currencySymbol] : [currencySymbol, price]}
                    </PriceLabel>
                    {priceLabel ? <DurationLabel>{priceLabel}</DurationLabel> : null}
                </PriceLabelContainer>
                {props.rating ? (
                    <RatingContainer>
                        <RatingStar src="/images/star.svg" />
                        <RatingLabel>{props.rating.toFixed(2)}</RatingLabel>
                    </RatingContainer>
                ) : null}
                {props.ratingAmount ? (
                    <RatingDescriptionLabel>
                        {props.ratingLabel || `${props.ratingAmount}+ Reviews`}
                    </RatingDescriptionLabel>
                ) : null}
            </Wrapper>
        </WrapperContainer>
    );
};
