import { IMessageProcessor } from '../types';

export const carouselProcessor: IMessageProcessor = (currentMessage) => {
    if (
        currentMessage.bot?.flight_search_result ||
        currentMessage.bot?.accommodation_search_result ||
        currentMessage.bot?.car_hire_search_result ||
        currentMessage.bot?.carousel ||
        currentMessage.bot?.places ||
        currentMessage.bot?.activity_search_result
    ) {
        currentMessage.isCarousel = true;
    }
};
