import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const Breakfast = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.454 9.19222C14.1636 8.82041 13.7266 8.60713 13.2548 8.60713H11.844V7.53125H1.22354V10.7265C1.22354 12.5215 2.12526 14.1096 3.49926 15.0625H1.22726V16H11.8403V15.0625H9.56832C10.0488 14.7293 10.4714 14.3185 10.818 13.8482H11.847C13.0517 13.8482 14.0971 13.032 14.3893 11.8633L14.7308 10.4975C14.8452 10.0398 14.7443 9.56409 14.454 9.19222V9.19222ZM13.8213 10.2701L13.4798 11.6359C13.2922 12.3865 12.6208 12.9107 11.847 12.9107H11.37C11.6743 12.2448 11.844 11.5051 11.844 10.7265V9.54466H13.2548C13.4359 9.54466 13.6036 9.6265 13.715 9.76922C13.8265 9.91191 13.8652 10.0945 13.8213 10.2701V10.2701Z"
                    fill="white"
                />
                <path
                    d="M6.53901 3.69644C5.68726 3.69644 4.99432 4.38938 4.99432 5.24113V6.31697H5.93182V5.24113C5.93182 4.90631 6.2042 4.63394 6.53901 4.63394H10.8424C12.8807 4.63394 14.539 2.97569 14.539 0.937375V0H13.6015V0.937375C13.6015 2.45875 12.3638 3.69644 10.8424 3.69644H6.53901Z"
                    fill="white"
                />
                <path
                    d="M3.78 5.37959C3.78 3.85822 5.01769 2.62053 6.53906 2.62053H10.8425C11.6942 2.62053 12.3872 1.92756 12.3872 1.07581V0H11.4497V1.07584C11.4497 1.41066 11.1773 1.68306 10.8425 1.68306H6.53906C4.50075 1.68306 2.8425 3.34131 2.8425 5.37962V6.31697H3.78V5.37959Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
