import { IMessageProcessor } from '../types';
enum HintType {
    Reply = 0,
    SendLocation = 1,
    SendUserEmail = 2,
}

export const hintProcessor: IMessageProcessor = (currentMessage) => {
    if (currentMessage.bot?.hints) {
        currentMessage.bot.hints = currentMessage.bot.hints.filter(
            (curValue) =>
                curValue.type !== HintType.SendLocation && curValue.type !== HintType.SendUserEmail
        );
    }
};
