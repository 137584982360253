import { useStore } from 'lib/store';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useRequireRegistrationState } from './UseGlobalState';

const { publicRuntimeConfig } = getConfig();

export const useSetRequireRegistration = () => {
    const setRequireRegistration = useRequireRegistrationState(
        (state) => state.setRequireRegistration
    );
    const isWidget = useStore((store) => store.isWidget);
    useEffect(() => {
        let requireRegistration = true;
        if (
            publicRuntimeConfig.enabledRegistrationDomains &&
            Array.isArray(publicRuntimeConfig.enabledRegistrationDomains)
        ) {
            let originUrl = '';
            if (isWidget && document.referrer) {
                originUrl = document.referrer;
            } else {
                originUrl = document.location.href;
            }
            const hostname = new URL(originUrl).hostname;
            requireRegistration = !!publicRuntimeConfig.enabledRegistrationDomains.find(
                (domain: string) => hostname.includes(domain)
            );
        }
        if (
            publicRuntimeConfig.disabledRegistrationUAs &&
            Array.isArray(publicRuntimeConfig.disabledRegistrationUAs)
        ) {
            if (
                publicRuntimeConfig.disabledRegistrationUAs.find((userAgent: string) =>
                    navigator.userAgent.includes(userAgent)
                )
            ) {
                requireRegistration = false;
            }
        }
        setRequireRegistration(requireRegistration);
    }, []);
};
