import UserAvatar from 'components/atoms/UserAvatar';
import { Heart, RedHeart } from 'components/icons';
import { Event, LikedOfferEvent, sendEvent } from 'lib/events';
import { GTM_EVENTS } from 'lib/gtmEvents';
import { useStore } from 'lib/store';
import React, { useCallback } from 'react';
import TagManager from 'react-gtm-module';
import styled, { useTheme } from 'styled-components';

import {
    getStateKey,
    useChannelLikesState,
    useGroupState,
    useUserState,
} from '../hooks/UseGlobalState';
import { ISendMessageOptions, MessageOfferType, MessageType } from '../types';
import { getUserInitials } from '../utils';

const LikesWrapper = styled.div((props) => ({
    position: 'relative',
    top: -14,
    left: 10,
    height: 36,
    paddingLeft: 10,
    paddingRight: 10,
    background: props.theme.colors.background,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: props.theme.borderRadius.likes,
    textAlign: 'center',
    lineHeight: '46px',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: -10,
}));

const LikeUserAvatar = styled.div<{ marginRight: number }>((props) => ({
    fontSize: '11px',
    display: 'flex',
    marginRight: props.marginRight,
}));

const AvatarsWrapper = styled.div({
    paddingLeft: 8,
    display: 'inline-flex',
});

interface ILikeProps {
    offerId: string;
    offerType: MessageOfferType;
    sendMessage: (text: string, options: ISendMessageOptions) => void;
}

export const Likes = (props: ILikeProps) => {
    const likes = useChannelLikesState(
        useCallback(
            (state) => state[getStateKey(props.offerType)]?.[props.offerId],
            [props.offerType, props.offerId]
        )
    );
    const userID = useUserState((state) => state.user?.id);
    const liked = !!userID && likes?.includes(`web:${userID}`);
    const otherGroupUsers = useGroupState((state) =>
        state.users.filter((user) => user.id !== userID && likes?.includes(`web:${user.id}`))
    );

    const HeartComponent = liked ? RedHeart : Heart;
    const theme = useTheme();

    const isWidget = useStore((store) => store.isWidget);
    if (isWidget) {
        return null;
    }

    return (
        <LikesWrapper
            onClick={() => {
                let likeType;
                switch (props.offerType) {
                    case MessageOfferType.Flights:
                        likeType = 'flight';
                        break;
                    case MessageOfferType.CarRental:
                        likeType = 'car_rental';
                        break;
                    case MessageOfferType.Activity:
                        likeType = 'activity';
                        break;
                    case MessageOfferType.Accommodation:
                        likeType = 'accommodation';
                        break;
                }
                const chatEvent: LikedOfferEvent = {
                    type: liked ? Event.UNLIKED_OFFER : Event.LIKED_OFFER,
                    like_type: likeType,
                };
                sendEvent(chatEvent);
                TagManager.dataLayer({
                    dataLayer: {
                        event: liked ? GTM_EVENTS.UNLIKED_OFFER : GTM_EVENTS.LIKED_OFFER,
                    },
                });
                props.sendMessage('', {
                    message_type: liked ? MessageType.Unlike : MessageType.Like,
                    offer: { type: props.offerType, id: props.offerId },
                    assistant_disabled: false,
                });
            }}
        >
            <HeartComponent height={20} width={21} color={theme.colors.icon} />
            {otherGroupUsers.length > 0 ? (
                <AvatarsWrapper>
                    {otherGroupUsers.slice(0, 3).map((user, index) => {
                        const userInitials = getUserInitials(user);
                        return (
                            <LikeUserAvatar
                                key={index}
                                marginRight={index === otherGroupUsers.length - 1 ? 0 : 3}
                            >
                                <UserAvatar index={index} height={22} width={22}>
                                    {userInitials}
                                </UserAvatar>
                            </LikeUserAvatar>
                        );
                    })}
                </AvatarsWrapper>
            ) : null}
            {otherGroupUsers.length > 3 ? (
                <LikeUserAvatar marginRight={0}>
                    <UserAvatar index={4} height={22} width={22}>{`+${
                        otherGroupUsers.length - 3
                    }`}</UserAvatar>
                </LikeUserAvatar>
            ) : null}
        </LikesWrapper>
    );
};
