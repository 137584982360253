import styled from 'styled-components';

interface IProps {
    height?: string | number;
    padding?: string;
}

export const RoundedLink = styled.a<IProps>((props) => ({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    padding: props.padding ? props.padding : '8px 16px',
    height: props.height ? props.height : '36px',
    cursor: 'pointer',
    background: props.theme.colors.background,
    border: '1px solid',
    borderColor: props.theme.colors.primary,
    boxSizing: 'border-box',
    borderRadius: props.theme.borderRadius.quickReplies,
    color: props.theme.colors.icon,
    userSelect: 'none',
    fontFamily: props.theme.fonts.default,
}));
