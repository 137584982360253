import { AccommodationDeal } from 'components/chat/types';
import React from 'react';
import styled from 'styled-components';

const DealSaleContainer = styled.div({
    position: 'absolute',
    height: '71px',
    minWidth: '220px',
    maxWidth: '330px',
    top: '33px',
    right: '0',
    display: 'flex',
    flexDirection: 'column',
});

const DealDiscountSale = styled.div((props) => ({
    background: '#FF0000',
    fontFamily: props.theme.fonts.bold,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '71px',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    letterSpacing: '3px',
    borderBottomLeftRadius: '12px',
    borderTopLeftRadius: '12px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
}));

export const Sale = (props: AccommodationDeal) => {
    let text = props.name;
    if (props.discount_percentage > 0 && props.discount_percentage < 100) {
        text = `${text} -${props.discount_percentage}%`;
    }
    return (
        <DealSaleContainer dir="auto">
            <DealDiscountSale>{text}</DealDiscountSale>
        </DealSaleContainer>
    );
};
