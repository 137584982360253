import UserAvatar from 'components/atoms/UserAvatar';
import Avatar from 'components/common/Avatar';
import { AtSign } from 'components/icons';
import { useStore } from 'lib/store';
import { GroupUser, GroupUserRole, GroupUserStatus } from 'lib/types';
import { useWindowSize } from 'lib/utils';
import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { useGroupState } from '../hooks/UseGlobalState';
import { useMenu } from '../hooks/UseMenu';
import { getUserInitials } from '../utils';

const TagWrapper = styled.div((props) => ({
    position: 'relative',
    left: 0,
    bottom: 0,
    display: 'flex',
    backgroundColor: props.theme.colors.background,
    justifyItems: 'center',
    padding: '16px',
    paddingRight: '0px',
    alignItems: 'center',
    ':hover': {
        cursor: 'pointer',
    },
}));

const TaggableUsersWrapper = styled.div<{ windowWidth: number }>((props) => ({
    position: 'absolute',
    background: props.theme.colors.background,
    width: 375,
    bottom: isMobile ? 53 : 55,
    left: 1,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    '@media (max-width: 600px)': {
        width: props.windowWidth,
    },
    overflowY: 'auto',
    maxHeight: 320,
}));

const TaggableUserRow = styled.div((props) => ({
    display: 'flex',
    fontSize: 16,
    fontFamily: props.theme.fonts.default,
    lineHeight: '19px',
    color: props.theme.colors.text,
    width: '100%',
    '&:hover': {
        backgroundColor: '#F0F0F0',
    },
}));

const TaggableUserAvatar = styled.div({
    margin: 14,
});

const TaggableUserName = styled.div({
    lineHeight: '64px',
});

const EddyAvatar = styled.div({
    svg: {
        width: 40,
        height: 40,
    },
    marginLeft: 14,
    marginTop: 14,
    marginRight: 14,
});

export const UserTags = ({ onUserClick }: { onUserClick: (user: GroupUser) => void }) => {
    const groupUsers = useGroupState((state) =>
        state.users.filter((user) => user.status === GroupUserStatus.Active && !!user.email)
    );
    const { isOpen, menuRef, setIsOpen } = useMenu();
    const windowSize = useWindowSize();
    const usersWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (usersWrapperRef.current && isOpen) {
            usersWrapperRef.current.scrollTop = (groupUsers.length + 1) * 64;
        }
    }, [isOpen]);

    const botTag = useStore((store) => store.config.botTag);
    return (
        <TagWrapper ref={menuRef} onClick={() => setIsOpen((open) => !open)}>
            <AtSign />
            {isOpen ? (
                <TaggableUsersWrapper
                    windowWidth={windowSize.width - (isMobile ? 4 : 18)}
                    ref={usersWrapperRef}
                >
                    {groupUsers.map((user, index) => {
                        return (
                            <TaggableUserRow key={user.id} onClick={() => onUserClick(user)}>
                                <TaggableUserAvatar>
                                    <UserAvatar width={40} height={40} index={index}>
                                        {getUserInitials(user)}
                                    </UserAvatar>
                                </TaggableUserAvatar>
                                <TaggableUserName>{user.email}</TaggableUserName>
                            </TaggableUserRow>
                        );
                    })}
                    <TaggableUserRow
                        onClick={() =>
                            onUserClick({
                                id: botTag!,
                                first_name: botTag!,
                                role: GroupUserRole.Member,
                                status: GroupUserStatus.Active,
                            })
                        }
                    >
                        <EddyAvatar>
                            <Avatar />
                        </EddyAvatar>
                        <TaggableUserName>EddyTravels - Travel Assistant</TaggableUserName>
                    </TaggableUserRow>
                </TaggableUsersWrapper>
            ) : null}
        </TagWrapper>
    );
};

export default UserTags;
