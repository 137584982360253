import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const PetsAllowed = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.0062 11.3063C12.3623 10.8411 11.9862 10.3699 11.8547 9.86508C11.3672 7.9804 9.79289 6.60471 8.02652 6.60471C6.26038 6.60471 4.62707 7.9804 4.13953 9.86601C4.00807 10.369 3.63192 10.8411 2.98777 11.307C2.20364 11.875 1.84502 12.7535 2.05166 13.6006C2.30004 14.6197 3.43173 15.5261 4.9797 15.5261C5.63422 15.5261 6.25576 15.3582 6.77629 15.0411C7.122 14.8291 7.59248 14.7168 8.0249 14.7168C8.4571 14.7168 8.86762 14.8284 9.21794 15.0401C9.74008 15.3591 10.3582 15.5277 11.0067 15.5277C12.5537 15.5277 13.6923 14.6259 13.9426 13.6008C14.1492 12.7535 13.7897 11.8741 13.0062 11.3063Z"
                    fill="white"
                />
                <path
                    d="M3.54105 6.6047C3.54105 5.58026 2.73109 3.9488 1.77053 3.9488C0.810194 3.9488 0 5.58026 0 6.6047C0 7.58072 0.794511 8.37523 1.77053 8.37523C2.74654 8.37523 3.54105 7.58072 3.54105 6.6047V6.6047Z"
                    fill="white"
                />
                <path
                    d="M14.2235 3.9488C13.2631 3.9488 12.4529 5.58026 12.4529 6.6047C12.4529 7.58072 13.2475 8.37523 14.2235 8.37523C15.1995 8.37523 15.994 7.58072 15.994 6.6047C15.994 5.58026 15.184 3.9488 14.2235 3.9488V3.9488Z"
                    fill="white"
                />
                <path
                    d="M8.9119 3.06342C8.9119 4.03967 9.70641 4.83418 10.6824 4.83418C11.6584 4.83418 12.453 4.03967 12.453 3.06342C12.453 2.03898 11.643 0.407749 10.6824 0.407749C9.72187 0.407749 8.9119 2.03898 8.9119 3.06342Z"
                    fill="white"
                />
                <path
                    d="M5.31181 4.83418C6.28783 4.83418 7.08234 4.03967 7.08234 3.06342C7.08234 2.03898 6.27214 0.407749 5.31181 0.407749C4.35125 0.407749 3.54105 2.03898 3.54105 3.06342C3.54105 4.03967 4.33556 4.83418 5.31181 4.83418Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
