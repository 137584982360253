import { CardLink } from 'components/molecules/link';
import { Card } from 'components/organisms/card';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Place, TextDirection } from '../types';

interface IProps {
    place: Place;
    direction: TextDirection;
}

const PlacesTitle = styled.div<{ direction?: TextDirection }>((props) => ({
    lineHeight: '17px',
    height: '44px',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: props.theme.fonts.default,
    color: props.theme.colors.text,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    display: '-webkit-box',
    ' -webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    direction: props.direction || 'ltr',
}));

export const PlaceCard = React.memo(({ place, direction }: IProps) => {
    const { t } = useTranslation('cards');
    return (
        <Card
            key={place.id}
            imageUrl={(place.images || [])[0]}
            title={<PlacesTitle direction={direction}>{place.title}</PlacesTitle>}
            subtitle={place.subtitle}
            url={place.directions_url}
            externalURL={true}
            primaryButton={<CardLink href={place.directions_url}>{t('place.directions')}</CardLink>}
            direction={direction}
        />
    );
});
