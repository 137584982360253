import { api } from 'lib/api';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { AccommodationOffer, ActivityOffer, FlightOffer } from '../types';

const OFFER_LIMIT = 5;

interface ICarouselState {
    isLoading: boolean;
    finishedLoading: boolean;
    offset: number;
}

export const useCarouselHandler = (
    initialOffers: Array<FlightOffer | AccommodationOffer | ActivityOffer>,
    searchId: string
) => {
    const getOffersFn = isFlightOffer(initialOffers[0]) ? api.getFlightOffers : api.getHotelOffers;
    const [state, setState] = useState<ICarouselState>({
        isLoading: false,
        finishedLoading: false,
        offset: initialOffers.length,
    });
    const [offers, setOffers] =
        useState<Array<FlightOffer | AccommodationOffer | ActivityOffer>>(initialOffers);
    const loadMore = async () => {
        if (state.finishedLoading || state.isLoading) {
            return;
        }
        try {
            setState((state) => ({ ...state, isLoading: true }));
            const response = await getOffersFn({
                searchId,
                offset: state.offset,
                limit: OFFER_LIMIT,
            });
            setState((state) => ({
                ...state,
                finishedLoading: response.data.length < OFFER_LIMIT,
                offset: state.offset + response.data.length,

                isLoading: false,
            }));
            setOffers((offers) => offers.concat(response.data));
        } catch (e) {
            setState((state) => ({ ...state, isLoading: false, finishedLoading: true }));
        }
    };
    const [inViewRef, inView] = useInView({
        threshold: 0.6,
    });
    useEffect(() => {
        if (inView) {
            loadMore();
        }
    }, [inView]);
    return { offers, inViewRef, isLoading: state.isLoading };
};

const isFlightOffer = (offer: FlightOffer | AccommodationOffer | ActivityOffer) => {
    return 'OutboundLeg' in offer && 'InboundLeg' in offer && 'CabinClass' in offer;
};
