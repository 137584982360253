import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const SwimmingPool = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.3089 10.0536L10.5402 8.81462C11.3469 9.81703 12.8397 9.86684 13.7112 8.94012C14.507 8.09396 14.4664 6.76512 13.6201 5.96924C12.7743 5.17374 11.4452 5.21409 10.6493 6.0604C10.0378 6.71059 9.92691 7.63506 10.2712 8.38099L8.41166 5.38399L11.1711 4.58771C12.0281 4.34043 12.4331 3.35949 11.9977 2.57928C11.6815 2.01278 11.0253 1.74271 10.4021 1.92259L6.22444 3.12809C5.14129 3.44068 4.65623 4.69981 5.25098 5.65843L6.51366 7.69353L4.29919 9.06756C6.96335 9.12878 8.88829 9.93993 11.3089 10.0536V10.0536Z"
                    fill="white"
                />
                <path
                    d="M0.597134 11.4339C6.65329 9.71237 8.94051 13.1879 15.6496 11.4366C15.9001 11.3712 16.0502 11.1151 15.9848 10.8647C15.9194 10.6142 15.6633 10.4642 15.4129 10.5295C8.9671 12.2121 6.67673 8.73106 0.340791 10.5322C0.0917905 10.6029 -0.0527095 10.8622 0.0180717 11.1112C0.0888842 11.3603 0.348228 11.5047 0.597134 11.4339V11.4339Z"
                    fill="white"
                />
                <path
                    d="M15.4129 12.7172C8.95526 14.4029 6.68929 10.9151 0.340791 12.7198C0.0917905 12.7906 -0.0527095 13.0499 0.0180717 13.2989C0.088853 13.5479 0.348197 13.6924 0.597134 13.6216C6.65329 11.9001 8.94051 15.3756 15.6496 13.6243C15.9001 13.5589 16.0502 13.3028 15.9848 13.0523C15.9194 12.8018 15.6633 12.6518 15.4129 12.7172V12.7172Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
