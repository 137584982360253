import ChatWindow from 'components/chat/engine/ChatWindow';
import React from 'react';
import styled from 'styled-components';

import { useGroupState, useUserState } from './hooks/UseGlobalState';

interface ChatProps {
    messages: Array<any>;
    sendMessage: (message: string) => void;
    channelHandle?: string;
}

const ChatWindowWrap = styled.div({
    height: '100%',
    maxWidth: '640px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
});

export const ChatComponent: React.FC<ChatProps> = ({ messages, sendMessage, channelHandle }) => {
    const groupUsers = useGroupState((state) => state.users);
    const user = useUserState((state) => state.user);
    return (
        <ChatWindowWrap>
            <ChatWindow
                messages={messages}
                sendMessage={sendMessage}
                user={user}
                groupUsers={groupUsers}
                channelHandle={channelHandle}
            />
        </ChatWindowWrap>
    );
};
