import React from 'react';

import { AmenityWrapper } from './AmenityWrapper';

export const FreeParking = (key: number) => {
    return (
        <AmenityWrapper key={key}>
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.90606 4.17404L6.78259 4.17616L6.7824 4.17269C6.49418 4.17269 6.26065 4.40741 6.26065 4.69444V7.13057C6.26065 7.4186 6.49418 7.65232 6.7824 7.65232H9.90587C10.3892 7.65232 10.7824 7.25898 10.7824 6.77563V5.05076C10.7824 4.56741 10.3892 4.17404 9.90606 4.17404Z"
                    fill="white"
                />
                <path
                    d="M12.8383 0H3.16166C1.41828 0 0 1.41847 0 3.16166V12.8383C0 14.5815 1.41828 16 3.16166 16H12.8383C14.5815 16 16 14.5815 16 12.8383V3.16166C16 1.41847 14.5815 0 12.8383 0ZM12.8696 6.77547C12.8696 8.40963 11.5401 9.73913 9.90609 9.73913H6.26088V13.3913C6.26088 13.6793 6.02734 13.9131 5.73912 13.9131H4.69566C4.40744 13.9131 4.17391 13.6793 4.17391 13.3913V2.63994C4.17391 2.38756 4.35459 2.17119 4.60291 2.12634C4.95078 2.06375 4.85159 2.08697 9.90606 2.08697C11.5401 2.08697 12.8695 3.41647 12.8695 5.05062V6.77547H12.8696Z"
                    fill="white"
                />
            </svg>
        </AmenityWrapper>
    );
};
