import { Scroller } from 'components/organisms/Scroller';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ICardWrapperDivProps {
    isLastCard: boolean;
    isFirstCard: boolean;
}
export const CardWrapperDiv = styled.div<ICardWrapperDivProps>((props) => ({
    scrollSnapAlign: 'center',
    paddingRight: props.isLastCard ? 8 : 0,
    paddingLeft: 8,
}));

const Carousel = (props: { children: ReactNode }) => {
    if (React.Children.count(props.children) <= 1) {
        return (
            <CardWrapperDiv isLastCard={true} isFirstCard={true}>
                {React.Children.toArray(props.children)[0]}
            </CardWrapperDiv>
        );
    }

    return (
        <Scroller leftButtonOffset={0} step={272} verticalCenter="24%">
            {React.Children.map(props.children, (card, i) => {
                const isLastCard = React.Children.count(props.children) === i;
                return (
                    <CardWrapperDiv isFirstCard={i === 0} isLastCard={isLastCard} key={i}>
                        {card}
                    </CardWrapperDiv>
                );
            })}
        </Scroller>
    );
};

export default Carousel;
