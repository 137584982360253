import styled from 'styled-components';

interface IBubbleProps {
    background?: string;
    color?: string;
    isFirst?: boolean;
}
export const Bubble = styled.div<IBubbleProps>((props) => ({
    background: props.background,
    color: props.color,
    maxWidth: '425px',
    padding: '10px',
    fontFamily: props.theme.fonts.default,
}));
