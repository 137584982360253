import { AspectRatio } from 'components/chat/types';
import React, { useState } from 'react';
import styled from 'styled-components/native';

interface ImageProps {
    hover?: boolean;
    aspectRatio?: number;
    imageUrl: string;
}

const InnerImage = styled.Image<ImageProps>((props) => ({
    cursor: 'pointer',
    width: '20rem',
    height:
        props.aspectRatio === AspectRatio.Square
            ? '20rem'
            : props.aspectRatio === AspectRatio.Horizontal
            ? '10.5rem'
            : '15rem',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("${props.imageUrl}")`,
}));

export const Image = (props: any) => {
    const [hover, setHover] = useState(false);
    return (
        <InnerImage
            {...props}
            hover={hover}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        />
    );
};
