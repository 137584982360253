import { useChatInputState, useMessagesState } from 'components/chat/hooks/UseGlobalState';
import { useLocationAutocomplete } from 'components/chat/hooks/useLocationAutocomplete';
import { useOutsideClick } from 'components/chat/hooks/useOutsideClick';
import { Location, LocationType } from 'components/chat/types';
import { getCountry } from 'lib/utils';
import { useRef } from 'react';
import styled from 'styled-components';

const AutoCompleteWrapper = styled.div({
    width: '100%',
});

const LocationRowWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '270px',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
});
const LocationRow = styled.div((props) => ({
    display: 'flex',
    flex: 'none',
    alignItems: 'center',
    paddingTop: '7px',
    paddingBottom: '7px',
    paddingLeft: '14px',
    paddingRight: '14px',
    cursor: 'pointer',
    ':hover': {
        backgroundColor: props.theme.colors.grey,
        'div:first-of-type': {
            backgroundColor: '#fff',
        },
    },
}));

const Flag = styled.div((props) => ({
    display: 'block',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    padding: '10px',
    backgroundColor: props.theme.colors.grey,
    fontSize: '18px',
    lineHeight: '22px',
    fontFamily: props.theme.fonts.default,
}));

const CountryName = styled.div((props) => ({
    fontFamily: props.theme.fonts.default,
    fontSize: '16px',
    lineHeight: '19px',
    paddingLeft: '13px',
    display: 'flex',
}));

const getLocationName = (location: Location): string => {
    if (location.Type === LocationType.City) {
        if (location.State && getCountry(location).ISO === 'US') {
            return `${location.Name}, ${location.State.Name}, ${getCountry(location).ISO}`;
        }
        return `${location.Name}, ${getCountry(location).ISO}`;
    } else if (location.Type === LocationType.Country) {
        if (location.MainCity) {
            return `${location.MainCity.Name}, ${getCountry(location).ISO}`;
        }
        return `${getCountry(location).Name}`;
    } else if (location.Type === LocationType.State) {
        if (location.MainCity) {
            return `${location.MainCity?.Name}, ${location.Name}, ${getCountry(location).ISO}`;
        }
        return `${location.Name}, ${getCountry(location).ISO}`;
    } else if (location.Type === LocationType.Airport) {
        return `${location.Name} (${location.IATA}),  ${getCountry(location).ISO}`;
    } else if (location.Type === LocationType.Pin) {
        return `${location.Name}, ${location.City?.Name}, ${getCountry(location.City!).ISO}`;
    }

    return location.Name;
};

const getFlag = (location: Location): string => {
    switch (location.Type) {
        case LocationType.Airport:
            return '🛫';
        case LocationType.Pin:
            return '📍';
        default:
            return getCountry(location).Flag!;
    }
};

export const Autocomplete = () => {
    const { locations, setLocations } = useLocationAutocomplete();
    const setShouldSend = useChatInputState((state) => state.setShouldSend);
    const setInput = useChatInputState((state) => state.setInput);
    const hasLocations = locations.length ? true : false;

    const ref = useRef(null);
    useOutsideClick(ref, () => {
        setLocations([]);
    });

    return !hasLocations ? null : (
        <AutoCompleteWrapper ref={ref}>
            <LocationRowWrapper>
                {locations.map((location, i) => (
                    <LocationRow
                        key={i}
                        onClick={() => {
                            setInput(getLocationName(location));
                            setLocations([]);
                            setShouldSend(true);
                        }}
                    >
                        <Flag>{getFlag(location)}</Flag>
                        <CountryName>{getLocationName(location)}</CountryName>
                    </LocationRow>
                ))}
            </LocationRowWrapper>
        </AutoCompleteWrapper>
    );
};
